import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import profile from "../../assets/images/home/profile.png";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

export default function HomeAlumni() {
  return (
    <>
      <h4 className=" font">Alumni</h4>
      <div>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
          }}
          style={{ margin: "0px" }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div
              className=" col-lg-12 col-md-12 col-xs-12 bggrey overflow-hidden"
              style={{
                borderBottom: "#B70003 solid 5px",
                padding: "40px 0px",
              }}
            >
              <div className=" col-md-4 col-xs-12">
                <div
                  className=" "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={profile}
                    style={{ height: "200px", width: "200px", margin: 0 }}
                  />
                </div>
              </div>
              <div className=" col-md-8 col-xs-12">
                <div className="home-Alumni">
                  <h3
                    className=" font font-center-sm-heading"
                    style={{ color: "#a70000" }}
                  >
                    Dr. M. Sathishkumar, M.P.T{" "}
                  </h3>
                  <h4
                    className=" font font-center-sm"
                    style={{
                      color: "#4a4a4a",
                      fontSize: "18px",
                      padding: 0,
                      margin: 0,
                      fontWeight: "600",
                    }}
                  >
                    Founder
                  </h4>
                  <p
                    className=" font font-center-sm"
                    style={{
                      color: "#222",
                      fontSize: "18px",
                      padding: 0,
                      margin: 0,
                      fontWeight: "400",
                    }}
                  >
                    School for Perfect Vision, Coimbatore
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className=" col-lg-12 col-md-12 col-xs-12 bggrey overflow-hidden"
              style={{
                borderBottom: "#B70003 solid 5px",
                padding: "40px 0px",
              }}
            >
              <div className=" col-md-4 col-xs-12">
                <div
                  className=" "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={profile}
                    style={{ height: "200px", width: "200px", margin: 0 }}
                  />
                </div>
              </div>
              <div className=" col-md-8 col-xs-12">
                <div className="home-Alumni">
                  <h3
                    className=" font font-center-sm-heading"
                    style={{ color: "#a70000" }}
                  >
                    Dr. M. Selvam, B.P.T
                  </h3>
                  <h4
                    className=" font font-center-sm"
                    style={{
                      color: "#4a4a4a",
                      fontSize: "18px",
                      padding: 0,
                      margin: 0,
                      fontWeight: "600",
                    }}
                  >
                    Physiotherapist
                  </h4>
                  <p
                    className=" font font-center-sm"
                    style={{
                      color: "#222",
                      fontSize: "18px",
                      padding: 0,
                      margin: 0,
                      fontWeight: "400",
                    }}
                  >
                    Indian Parliament House, New Delhi
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              className=" col-lg-12 col-md-12 col-xs-12 bggrey overflow-hidden"
              style={{
                borderBottom: "#B70003 solid 5px",
                padding: "40px 0px",
              }}
            >
              <div className=" col-md-4 col-xs-12">
                <div
                  className=" "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={profile}
                    style={{ height: "200px", width: "200px", margin: 0 }}
                  />
                </div>
              </div>
              <div className=" col-md-8 col-xs-12">
                <div className="home-Alumni">
                  <h3
                    className=" font font-center-sm-heading"
                    style={{ color: "#a70000" }}
                  >
                    Dr. B. Thirumalaya, M.P.T
                  </h3>
                  <h4
                    className=" font font-center-sm"
                    style={{
                      color: "#4a4a4a",
                      fontSize: "18px",
                      padding: 0,
                      margin: 0,
                      fontWeight: "600",
                      width: "98%",
                    }}
                  >
                    Secured first rank in the “Medical Recruitment Board”
                    examination Government Hospital, Kumbakonam
                  </h4>
                  <p
                    className=" font font-center-sm"
                    style={{
                      color: "#222",
                      fontSize: "18px",
                      padding: 0,
                      margin: 0,
                      fontWeight: "400",
                    }}
                  >
                    Student Testimonials
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div align="center">
          <p>
            <a href="/alumni/alumni" class="btn-default btn6  font">
              View More
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
