import React from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import { HiHome } from "react-icons/hi";

export default function MPTCurriculum() {
  return (
    <div>
      <AboutProgramLayout>

        
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Program
                </a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{
                  fontSize: "18px",
                  color: "rgb(34, 34, 34)",
                  fontWeight: "500",
                }}
              >
                Program Overview
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                MPT
              </li>
            </ol>
          </nav>
        </section>
        <div class="p-4 card">
          <div class="my-1 px-2">
            <h1>Master of Physiotherapy Curriculum</h1>
            <div class=" mx-md-4 ">
              <h6 class="highlight">2 YEAR COURSE</h6>
              <h6 class="highlight">MEDIUM OF INSTRUCTION: ENGLISH</h6>
            </div>
          </div>
          <div style={{width:"100%", overflow:"auto"}}>
          <div class="table-responsive ">
            <div
              class="table-responsive"
              style={{ width: "96%", overflow: "auto" }}
            >
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th width="15%">SI.NO</th>
                    <th
                      width="15%"
                      style={{ textAlign: "center", marginTop: "100px" }}
                    >
                      YEAR
                    </th>
                    <th
                      width="30%"
                      style={{ textAlign: "center", marginTop: "100px" }}
                    >
                      PAPER
                    </th>
                    <th width="40%">MODULES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="5">
                      <div style={{ textAlign: "center", marginTop: "100px" }}>
                        <h6> 1</h6>
                      </div>
                    </td>
                    <td rowspan="10">
                      <div style={{ textAlign: "center", marginTop: "200px" }}>
                        <h6> Ⅰ YEAR</h6>
                      </div>
                    </td>
                    <td rowspan="5">
                      <div style={{ textAlign: "center", marginTop: "100px" }}>
                        <p> Paper I -Applied Basic Sciences </p>
                      </div>
                    </td>
                    <td>Biostatistics and Research Methodology</td>
                  </tr>
                  <tr>
                    <td>Biomechanics and Pathomechanics</td>
                  </tr>
                  <tr>
                    <td>Ergonomics</td>
                  </tr>
                  <tr>
                    <td>Nutrition and Exercise Physiology</td>
                  </tr>
                  <tr>
                    <td>Teaching Methodology in Physiotherapy</td>
                  </tr>
                  <tr>
                    <td rowspan="5">
                      <div style={{ textAlign: "center", marginTop: "100px" }}>
                        <h6> 2</h6>
                      </div>
                    </td>
                    <td rowspan="5">
                      <div style={{ textAlign: "center", marginTop: "100px" }}>
                        <p> Paper II -Physiotherapeutics </p>
                      </div>
                    </td>
                    <td>Manual Therapy</td>
                  </tr>
                  <tr>
                    <td>Exercise Therapy</td>
                  </tr>
                  <tr>
                    <td>Electrotherapy</td>
                  </tr>
                  <tr>
                    <td>Electrophysiology</td>
                  </tr>
                  <tr>
                    <td>Physical and Functional Diagnosis</td>
                  </tr>
                  <tr>
                    <td rowspan="4">
                      <div style={{ textAlign: "center", marginTop: "100px" }}>
                        <h6> 3</h6>
                      </div>
                    </td>
                    <td rowspan="7">
                      <div style={{ textAlign: "center", marginTop: "200px" }}>
                        <h6> Ⅱ YEAR</h6>
                      </div>
                    </td>
                    <td rowspan="4">
                      <div style={{ textAlign: "center", marginTop: "50px" }}>
                        <p>Speciality Paper I -Physiotherapy Assessment</p>
                      </div>
                    </td>
                    <td>Anatomy and Physiology Interventions</td>
                  </tr>
                  <tr>
                    <td>Clinical Conditions</td>
                  </tr>
                  <tr>
                    <td>Physiotherapy Assessment</td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td rowspan="2">
                      <div style={{ textAlign: "center", marginTop: "100px" }}>
                        <h6> 4</h6>
                      </div>
                    </td>
                    <td rowspan="2">
                      <div style={{ textAlign: "center", marginTop: "50px" }}>
                        <p>
                          {" "}
                          Speciality Paper II - Physiotherapy interventions{" "}
                        </p>
                      </div>
                    </td>
                    <td>Foundational concepts and conditions</td>
                  </tr>
                  <tr>
                    <td>Management Special Techniques</td>
                  </tr>
                  <tr>
                    <td>
                      <div style={{ textAlign: "center" }}>
                        <h6> 5</h6>
                      </div>
                    </td>
                    <td>
                      <div style={{ textAlign: "center" }}>Dissertation</div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
       
        </div>
      </AboutProgramLayout>
    </div>
  );
}
