import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";


// import 'rsuite/dist/styles/rsuite-default.css'; 
import { PieChart } from '@rsuite/charts'; 


// import { Card } from "react-bootstrap";
Chart.register(ArcElement);
const data = {
  labels: [
    "Across the world",
    "Government Sector",
    "various Health Sectors in india",
  ],
  datasets: [
    {
      data: [32, 7, 61],
      backgroundColor: [
        "#f38b4a",
        "#56d798",
        "#ff8397",
        "#6970d5" 
      ],
      hoverBackgroundColor: [
        "#f38b4a",
        "#56d798",
        "#ff8397",
        "#6970d5"
      ]
    },
  ],
};

export default function PieExample() {
  const sampleData = [ 
    ['Across the world', 32], 
    ['Government Sector', 7], 
    ['Various Health Sectors in india', 61], 
  ]; 
  return (
    <div class="col-12 ">
      <div className=" col-md-6">


<div className=" PieChart" style={{ 
      display: 'block', 
      overflow:"hidden"
    }}> 
      <PieChart name="PieChart" data={sampleData} /> 
    </div > 

        {/* <Pie data={data} style={{ margin: "0px auto" }} /> */}
      </div>
      {/* <div className=" col-md-6">
        <div>
          <div style={{ display: "flex", marginTop: "40px" }}>
            <div
              style={{
                height: "20px",
                marginRight: "6px",
                width: "20px",
                marginTop: "10px",
                background: "#FF6384",
              }}
            ></div>
            <div>
              <p style={{ fontSize: "18px", marginTop: "6px" }}>
                {" "}
                - Across the world
              </p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "20px",
                marginRight: "6px",
                width: "20px",
                marginTop: "10px",
                background: "#36A2EB",
              }}
            ></div>
            <div>
              <p style={{ fontSize: "18px", marginTop: "6px" }}>
                {" "}
                - Government Sector
              </p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "20px",
                marginRight: "6px",
                width: "20px",
                marginTop: "10px",
                background: "#FFCE56",
              }}
            ></div>
            <div>
              <p style={{ fontSize: "18px", marginTop: "6px" }}>
                {" "}
                - Various Health Sectors in india
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
