import React from "react";
import InstitudeLayout from "../Components/Layout/Institude/InstitudeLayout";
import Franklin from "../assets/images/management/Franklin.png";
import { HiHome } from "react-icons/hi";
export default function Principal() {
  return (
    <div>
      <InstitudeLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb code code--small code--right">
              <li className="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Institute
                </a>
              </li>

              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Principal
              </li>
            </ol>
          </nav>
        </section>
        <h1>Dr. M.K. Franklin Shaju</h1>
        <h3>MPT(Neuro)., MSPT(Sports)., PG Dip (Bio-Stat).,Ph.D,</h3>
        <h3>Principal of RVS College of Physiotherapy</h3>
        <div className="col-md-12 col-sm-12" id="nullevery">
          <div className="col-md-9 col-sm-9" id="nullevery">
            {" "}
            <p style={{lineHeight:"26px"}}>
              Dr. M.K. Franklin Shaju, our dedicated Principal, is a
              distinguished alumnus of the inaugural batch of RVS College of
              Physiotherapy (1993). Dr. Shaju has been at the forefront of our
              institution&#39;s academic endeavors with an illustrious tenure as
              a dedicated faculty member. He deeply understands the
              institution&#39;s ethos in his current role as Principal.
            </p>
          </div>
          <div className="col-md-3 col-sm-3">
            <img src={Franklin} className="img-responsive" alt="Franklin" />
          </div>
        </div>
      </InstitudeLayout>
    </div>
  );
}
