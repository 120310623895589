import OthersLayout from "../Components/Layout/Others/OthersLayout";
import { HiHome, HiOutlineChevronDoubleRight } from "react-icons/hi";

export default function Placements() {
  return (
    <div>
      <div>
        <OthersLayout>
          <section id="breadcrumb">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb code code--small code--right">
                <li className="breadcrumb-item">
                  <HiHome
                    style={{
                      fontSize: "23px",
                      paddingTop: "3px",
                      margin: "-2px 0px",
                    }}
                  />
                </li>
                <li className="breadcrumb-item">
                  <a href="" style={{ fontSize: "18px", color: "#222" }}>
                    Others
                  </a>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ fontSize: "18px" }}
                >
                  Placement
                </li>
              </ol>
            </nav>
          </section>
          <h1>Eco friendly campus</h1>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "13px" }}>Placements</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Exclusive soft skill training program and personality
                  development classNamees.
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Out of 1600 graduates nearly 500 were placed in abroad, few in
                  government service, others placed in a various health care
                  settings in India.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>About faculties </h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  The only college which possesses 3 doctorates faculty.
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Visiting faculties from Australia, Canada and USA.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>Awards and Rankings</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  RVS College of Physiotherapy was recognized by Higher
                  education review as “Top 10 Promising Coimbatore Colleges”.
                </div>
              </li>
            </ul>
          </div>

          <div
            style={{ marginBottom: "10px" }}
            className="col-md-12 col-sm-12 about-middle-grid border-enable "
          >
            <h3>Partnership/Tie-ups/MOU/Hospital Intern</h3>
            <div className="col-md-6 col-sm-12 ">
              <ul id="nullevery">
                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    Ramakrishna Mission Vivekananda University, Maruthi College
                    of Physical Education.
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    Ganga Hospitals, Coimbatore.
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    K.M.C.H Hospital, Sulur.
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    One care Medical Centre
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    Pondicherry Institute of Medical Sciences
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    JSS Hospitals, Mysore.
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    Amar seva Sangam, Thenkasi.
                  </div>
                </li>
              </ul>
              <br />
            </div>

            <div className="col-md-6 col-sm-12 ">
              <ul id="nullevery">
                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    BRJ Ortho Centre & MAK Hospital, Coimbatore.
                  </div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    Dr.Muthu’s Ortho Centre, Coimbatore.
                  </div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    RS Hospital, Coimbatore.
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    International Human resource and development centre,
                    Periyanaickenpalayam.
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    ARS Hospitals, Tirupur
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    Aristo medical center, Tirupur.
                  </div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <HiOutlineChevronDoubleRight
                      className=" primary "
                      style={{ marginRight: "3px", marginTop: "3px" }}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#2b2b2b",
                      lineHeight: "1.3em",
                    }}
                  >
                    St Marys Hospital – Podanur
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "13px" }}>Labs</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  RVS College of Physiotherapy is a university recognized first
                  research Centre equipped with advanced imported treatment
                  devices.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>RVS Physiotherapy clinic</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  RVS Physiotherapy clinic is providing cost effective treatment
                  with expertise consultant
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  The specialty wise online Physiotherapy consultation services
                  and treatments are available.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>Hostel</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Secure and Separate AC and Non-AC hostel rooms for boys and
                  girls.
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Vegetarian/Non-vegetarian meals are prepared hygienically
                  andserved aesthetically.
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Gym for boys and girls separately with advanced fitness
                  equipment.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>Medical camps</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Periodic free medical camps to underserved population.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>Transport</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  A fully fledged Transport service is offered to ensure
                  hassle-free and safe transportation which cover all major
                  destination.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>Sports club</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Sophisticated track and field, football, basketball and volley
                  ball court with qualified physical educators.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>Cultural club</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  RVS Yuva platform which gives opportunity to enhance student’s
                  artistic talent.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>Internship</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  The college has its own outpatient physiotherapy department
                  inside the campus and MOU signed with many multispecialty
                  hospitals for internship program.
                </div>
              </li>
            </ul>
          </div>

          <div style={{ paddingBottom: "18px" }} className="">
            <h3 style={{ marginTop: "0px" }}>Top Alumni</h3>
            <p>Alumni Association, “Physio Comrade” launched at 1998.</p>
            <p style={{ margin: 0, fontSize: "18px" }}>
              <b style={{ margin: 0, fontSize: "18px" }}>
                1 Dr.M.Sathishkumar,M.P.T –
              </b>
              1995 batch student, founder – School for perfect vision in
              Coimbatore. <br />
            </p>
            <p style={{ margin: 0, fontSize: "18px" }}>
              <b style={{ margin: 0, fontSize: "18px" }}>
                {" "}
                2 Dr.M.Selvam, B.P.T –
              </b>{" "}
              1998 batch Physiotherapist in Indian parliament house, New Delhi.{" "}
              <br />
            </p>

            <p style={{ margin: 0, fontSize: "18px" }}>
              <b style={{ margin: 0, fontSize: "18px" }}>
                3 Dr.B.Thirumalaya, M.P.T –
              </b>
              1999 Batch, secured first rank in “Medical Recruitment board”
              examination and working in Kumbakonam, Government Hospital.{" "}
            </p>
          </div>
        </OthersLayout>
      </div>
    </div>
  );
}
