import React, { useState } from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import { HiHome } from "react-icons/hi";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OthersLayout from "../Components/Layout/Others/OthersLayout";

export default function Admission() {
  const [sucessFully, setSucessFully] = useState(false);
  const [vaild, setValid] = useState(true);
  const [vaildStatus, setVaildStatus] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    course: "",
    phone: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    course: "",
    phone: "",
  });
  const handleChange = (e) => {
    const { name, value } = e?.target;
    setData({
      ...data,
      [name]: value,
    });
    console.log(data);
  };
  if (vaild === true) {
    // data.dob = startDate.toDateString().split(" ").slice(1).join(" ");
    // data.batch = startDate.getFullYear();
    // data.batch = new Date(startDate) * 1000;
    let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    let Number = /^[6-9]\d{9}$/;

    if (data.name === "") {
      errors.name = "Enter the Name";
    } else if (data.name === undefined) {
      errors.name = "Enter the Name";
    } else {
      errors.name = true;
    }

    if (data.phone === "") {
      errors.phone = "Enter the phone";
    } else if (data.phone === undefined) {
      errors.phone = "Enter the phone";
    } else if (!Number.test(data.phone)) {
      errors.phone = "Enter Correct Number";
    } else if (data.phone.length < 10 || data.phone.length > 20) {
      errors.phone = "vaild required";
    } else {
      errors.phone = true;
    }

    if (data.email === "") {
      errors.email = "Enter the email";
    } else if (data.email === undefined) {
      errors.email = "Enter the email";
    } else if (!regex.test(data.email)) {
      errors.email = "vaild email required";
    } else {
      errors.email = true;
    }

    if (data.course === "") {
      errors.course = "Select the course";
    } else if (data.course === undefined) {
      errors.course = "Select the course";
    } else {
      errors.course = true;
    }
  }
  const handleSubmit = (e) => {
    setVaildStatus(true);
    if (
      errors?.name === true &&
      errors.email === true &&
      errors.course === true &&
      errors.phone === true
    ) {
      axios
        .post("http://localhost:7000/api/rvscptadmission", data)
        .then((res) => {
          setData({
            name: "",
            email: "",
            course: "",
            phone: "",
          });
          setSucessFully(true);
          setVaildStatus(false);
          setTimeout(() => {
            setSucessFully(false);
          }, 3000);
          setErrors({
            name: "",
            email: "",
            course: "",
            phone: "",
          });
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("course").value = "";
          document.getElementById("phone").value = "";
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // axios.post()
    e.preventDefault();
  };
  return (
    <div>
      <OthersLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb code code--small code--right">
              <li className="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                Others
                </a>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Admission
              </li>
            </ol>
          </nav>
        </section>
        <h1> Admission </h1>

    

        <div className="row">
          <div className="col-md-6">
            <div>
              <form>
                <div className="form-group">
                  <label for="name">Name</label>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Enter Name"
                    onChange={(e) => handleChange(e)}
                    id="name"
                    name="name"
                  />
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.name}</span>
                  ) : null}
                </div>

                <div className="form-group">
                  <label for="Register Number">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the email"
                    onChange={(e) => handleChange(e)}
                    id="email"
                    name="email"
                  />
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.email}</span>
                  ) : null}
                </div>

                <div className="form-group">
                  <label for="sel1" className="form-label">
                    Course
                  </label>
                  <div>
                    <select
                      name="course"
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      id="course"
                    >
                      <option>UG</option>
                      <option>PG</option>
                      <option>Ph.D</option>
                    </select>
                  </div>
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.course}</span>
                  ) : null}
                </div>

                <div className="form-group">
                  <label for="Mobile Number">Mobile Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Mobile Number"
                    onChange={(e) => handleChange(e)}
                    name="phone"
                    id="phone"
                  />
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.phone}</span>
                  ) : null}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ padding: "4px 40px" }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>

                {sucessFully ? (
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "6px",
                      fontSize: "16px",
                      color: "#2b4e03",
                      fontWeight: "800",
                    }}
                  >
                    Form Submitted Succesfully
                  </p>
                ) : (
                  <p></p>
                )}
              </form>
            </div>
          </div>
        </div>
      </OthersLayout>
    </div>
  );
}
