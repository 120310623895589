import React, { useEffect, useRef, useState } from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import { NavLink } from "react-router-dom";
// import { AboutProgramContext } from "../../../Context/AboutProgramContext";

export default function AboutProgramSideNavbar() {
  const [forceRender, setForceRender] = useState(false);
  // console.log(AboutProgramContext);
  const [color,setColor]=useState()
  const myRef = useRef(null);
  const [navData, setnavData] = useState([
    {
      id: 0,
      nav_name: "Program Overview",
      status: false,
      nav_status: false,
      nav_link: "",
      data: [
        {
          sub_nav: "BPT ",
          sub_link: "/aboutprogram/programoverview/bpt",
        },
        {
          sub_nav: "MPT ",
          sub_link: "/aboutprogram/programoverview/mpt",
        },
        {
          sub_nav: "Ph.D ",
          sub_link: "/aboutprogram/programoverview/phd",
        },
      ],
    },
    {
      id: 1,
      nav_name: "Program Curriculum",
      status: false,
      nav_status: false,
      nav_link: "",
      data: [
        {
          sub_nav: "BPT ",
          sub_link: "/aboutprogram/curriculum/bpt",
        },
        {
          sub_nav: "MPT ",
          sub_link: "/aboutprogram/curriculum/mpt",
        },
      ],
    },
   
  ]);

  const handleFunction = ()=>{
    document.getElementById('nameId0').setAttribute("class", "classOne classTwo"); 
  }
  const handleClick = (v, i) => {
    // let arr =["bpt","mpt","bpt"]
    navData?.forEach((el) => {
      
      if (el?.id === v?.id) {
        el.status = true;
        el.nav_status = true;
        
        if(el.nav_name==="Program Overview" ){

          // document.getElementById('nameId0').setAttribute("class", "classOne classTwo"); 
          setForceRender(!forceRender);
        }
      } else {
        el.status = false;
      }
  

      setForceRender(!forceRender);
    });

    setnavData(navData);
  };
const generate = ()=>{
  console.log("hello")
  // document.getElementById('nameId0').setAttribute("class", "classOne classTwo"); 
}
 // Access the DOM element using ref
//  const handleClickButton = () => {
//   myRef.current.style.backgroundColor = 'blue';
// };
  useEffect(() => {
    // document.getElementById('nameId0').setAttribute("class", "classOne classTwo"); 
    document.getElementById("navID0").removeAttribute("href");
    document.getElementById("navID1").removeAttribute("href");
    document.getElementById("navID1").removeAttribute("class");
    document.getElementById("navID0").removeAttribute("class");
  }, []);
  return (
    <div>
      <div class="panel-group" id="accordion">
        {navData?.map((v, i) => {
          console.log(color)
          return (
            <>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <NavLink
                      to={v?.id === 0 || v?.id === 1 ? null : v?.nav_link}
                      id={`navID` + i}
                      style={{
                        lineHeight: "10px",
                        display: "flex",
                        // color: v?.id === 0 || v?.id === 1 ? "#222" : "",
                      }}
                    >
                      <HiChevronDoubleRight
                        style={{
                          marginTop: "2px",
                          color: v?.id === 0 || v?.id === 1 ? "#222" : "",
                        }}
                      />
                      <div
                        style={{
                          marginTop: "5px",
                          marginLeft: "2px",
                          color: v?.id === 0 || v?.id === 1 ? `${color}` : "",
                        }}
                        className=""
                        ref={myRef}
                        id={`nameId` + i}
                      >
                        {v?.nav_name}
                      </div>
                    </NavLink>
                  </h4>
                  <div>
                    <div style={{ padding: "0px 10px" }}>
                      {v.data?.map((el) => {
                        return (
                          <>
                            <NavLink to={el?.sub_link}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "16px",
                                }}
                                // onClick={(v, i) => {
                                //   handleClick(v, i)
                                //   // generate()
                                // }}
                                onClick={() => handleClick(v, i)}
                              >
                                {el?.sub_nav}
                              </div>
                            </NavLink>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
