import React from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import { HiHome, HiOutlineChevronDoubleRight } from "react-icons/hi";
import OthersLayout from "../Components/Layout/Others/OthersLayout";
import Frankiln from "../assets/images/management/Franklin.png";

export default function Careers() {
  return (
    <div>
      <div>
        <OthersLayout>
          <section id="breadcrumb">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb code code--small code--right">
                <li class="breadcrumb-item">
                  <HiHome
                    style={{
                      fontSize: "23px",
                      paddingTop: "3px",
                      margin: "-2px 0px",
                    }}
                  />
                </li>
                <li class="breadcrumb-item">
                  <a href="" style={{ fontSize: "18px", color: "#222" }}>
                    Others
                  </a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                  style={{ fontSize: "18px" }}
                >
                  Careers
                </li>
              </ol>
            </nav>
          </section>

          <h4>
            “Commencement of Your Career From RVS College of Physiotherapy”
          </h4>
          <p>
            Are you passionate about making a difference in the field of
            education and healthcare? At RVS College of Physiotherapy, we always
            look for dedicated and talented individuals to join our team. As a
            leading institution in the field of physiotherapy, we offer a
            stimulating and inclusive work environment where your skills and
            expertise can thrive.
          </p>
          <h4>Why Choose a Career at RVS College of Physiotherapy</h4>
          <p>
            Innovative Environment: We foster a culture of innovation and
            continuous learning. Join us to be a part of cutting-edge
            educational practices and contribute to the growth of the next
            generation of healthcare professionals.
          </p>
          <p>
            Professional Development: We invest in our staff's professional
            growth. Whether you're a faculty member, administrator, or support
            staff, you'll have opportunities to expand your skills and
            knowledge.
          </p>
          <p>
            Impactful Work: Your work at RVS College of Physiotherapy directly
            contributes to the education and development of future
            physiotherapists, helping shape the healthcare landscape.
          </p>
          <h4>What Our Academicians Say:</h4>
          <div class="col-md-12 bggrey">
            <div class="col-md-4 " align="center">
              <br />
              <img
                src={Frankiln}
                class="img-responsive"
                alt=""
                style={{ width: "100%" }}
              />
            </div>
            <div class="col-md-8" align="center">
              <h3 style={{ textAlign: "left" }}>Dr. M.K. Frankiln Shaju</h3>
              <p style={{ textAlign: "left" }}>
                I am honored to have been associated with this organization
                since its inception as a student and now as Principal. I enjoyed
                the classroom experience both as a student and also as a
                teacher. It brings me great joy that the student-centered
                Institution is always available through all means.
              </p>
              <br />
              <div align="center">
                <p>
                  <a href="/others/placement" class="btn-default btn6">
                    View More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </OthersLayout>
      </div>
    </div>
  );
}
