import React, { useState } from "react";
import OthersLayout from "../Components/Layout/Others/OthersLayout";
import { HiHome, HiOutlineChevronDoubleRight } from "react-icons/hi";
import Gallery001 from "../assets/images/gallery/Gallery001.png";
import Gallery003 from "../assets/images/gallery/Gallery003.png";
import Gallery005 from "../assets/images/gallery/Gallery005.png";
import Gallery006 from "../assets/images/gallery/Gallery006.png";
import Gallery007 from "../assets/images/gallery/Gallery007.png";
import Gallery008 from "../assets/images/gallery/Gallery008.png";
import Gallery009 from "../assets/images/gallery/Gallery009.png";
import Gallery010 from "../assets/images/gallery/Gallery010.png";
import { VscEye } from "react-icons/vsc";
import { BsEyeFill } from "react-icons/bs";

import { BsX } from "react-icons/bs";

export default function Gallery() {
  const [popupState, setPopupState] = useState(false);
  const [state, setState] = useState(false);

  const data = [
    { id: 0, img: Gallery001, alt: "" },
    { id: 1, img: Gallery003, alt: "" },
    { id: 2, img: Gallery005, alt: "" },
    { id: 3, img: Gallery006, alt: "" },
    { id: 4, img: Gallery007, alt: "" },
    { id: 5, img: Gallery008, alt: "" },
    { id: 6, img: Gallery009, alt: "" },
    { id: 7, img: Gallery010, alt: "" },
  ];

  const [imageData, setImageData] = useState();
  const handleImage = (v, i) => {
    var dataImage = [];
    data?.forEach((el) => {
      if (el?.id === v?.id) {
        dataImage.push(el);
      }
    });
    setState(!state);
    setPopupState(!popupState);
    setImageData(dataImage);
    console.log(v, i);
  };
  console.log(imageData);
  const handlePopup = () => {
    setState(!state);
    setPopupState(!popupState);
  };

  return (
    <div>
      <div>
        <OthersLayout>
          <section id="breadcrumb">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb code code--small code--right">
                <li class="breadcrumb-item">
                  <HiHome
                    style={{
                      fontSize: "23px",
                      paddingTop: "3px",
                      margin: "-2px 0px",
                    }}
                  />
                </li>
                <li class="breadcrumb-item">
                  <a href="" style={{ fontSize: "18px", color: "#222" }}>
                    Others
                  </a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                  style={{ fontSize: "18px" }}
                >
                  Gallery
                </li>
              </ol>
            </nav>
          </section>
          <h1>Gallery</h1>
          {state ? (
            <>
              <div className="model-popup" style={{ padding: "6px" }}>
                <div className=" gallery-images">
                  <div
                    className=" gallery-images-model"
                    onClick={() => handlePopup()}
                    style={{}}
                  >
                    <BsX className="gallery-images-model-cancel " />
                  </div>
                  <img src={imageData[0]?.img} alt="" />
                </div>
              </div>
            </>
          ) : null}

          {data?.map((v, i) => {
            return (
              <>
                <div className=" col-md-6 " style={{ marginBottom: 8 }}>
                  <div className=" gallery-images-div">
                    <div
                      className=" gallery-images-view"
                      onClick={() => handleImage(v, i)}
                    >
                      <div className=" gallery-view">
                        <BsEyeFill className=" gallery-view-icons" />
                      </div>
                    </div>
                    <img
                      src={v?.img}
                      className="gallery-images-grid "
                      style={{ margin: 0 }}
                    />
                  </div>
                </div>
              </>
            );
          })}
          {/* <img src={Gallery001} alt="Galley001" /> */}
        </OthersLayout>
      </div>
    </div>
  );
}
