import React from "react";
import AlumniSideNavbar from "./AluminiSideNavbar";

export default function AlumniLayout(props) {
  return (
    <div className=" h-[400px]">
      <main id="main">
        {/*  
 <!--==========================
   Banner Section
 ============================--> */}
        <section id="innerabout" class="wow fadeInUp">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 col-sm-12 equal padbtm smDeviceequal"
                id="greybg"
              >
                <div class="col-md-3 col-sm-4 code code--small code--right hidden-xs hidden-sm">
                  <div
                    class="acti-box inner-box-v6 box-shadow shadow-effect-2"
                    id="grad"
                  >
                    {/* <!-- accordion--> */}
                    <AlumniSideNavbar />
                    {/* <!-- accordion-->   */}
                  </div>
                </div>

                <div
                  class="col-md-9 about-middle-grid code code--small code--right"
                  id="nullevery"
                >
                  <div
                    class="inner-box acti-box-v6 box-shadow shadow-effect-2"
                    id="grad"
                  >
                    {props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- #about --> */}
      </main>
    </div>
  );
}
