import React, { useState } from "react";
import Banner from "../Components/Layout/Banner";
import HomeAlumni from "../Components/Layout/HomeAlumni";
import NewsEvents from "../Components/Layout/NewsEvents";
import Graph from "../assets/images/other-icons/graph.png";
import StudentTestimonials from "../Components/Layout/StudentTestimonials";
import ModelPopup from "../Components/Layout/PopUp";
import Whatsapp from "../assets/images/home/whatsup.png";
import Youtube from "../assets/images/home/youtube.png";
import FB from "../assets/images/home/fb.png";
import Instagram from "../assets/images/home/instagram.png";
import Admission from "../assets/images/home/admission-contact.png";
import HomeCard from "../Components/Layout/HomeCard";
import MoUPartners from "../Components/Layout/MoUPartners";
import PieExample from "./PieExample";
import ContactBox from "../Components/Layout/ContactBox";
import PieChart from "../Components/Layout/PieChart";

export default function Home() {
  const [open, setOpen] = useState(false);
  const handleModelBox = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Banner />
      <section id="event">
        <div class="container">
          <div class="row">
            <div class="col-md-12 aboutnews-middle-grids">
              {/* <!--GRID START--> */}

              <h4 className="font">Welcome to RVS College of Physiotherapy </h4>
              <p>
                At RVS College of Physiotherapy, education excellence is upheld
                by our dedicated faculty, ensuring top-notch physiotherapy
                education. Affiliated with The TN Dr. M.G.R. Medical University
                and recognized by 'The Indian Association of Physiotherapists,'
                our institution maintains superior academic standards and
                fosters a quality learning environment.
                <br />
                <br />
              </p>

              <HomeCard />

              <div align="center">
                <br />
                <p>
                  To know more about RVS College of Physiotherapy
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    class="btn-default btn6 md-trigger"
                    data-modal="modal-11"
                    onClick={handleModelBox}
                  >
                    Download Brochure
                  </a>
                </p>
              </div>
            </div>
            {/* <!--GRID END--> */}
          </div>
        </div>
      </section>

      <section id="event">
        <div class="container">
          <div class="row">
            <div class="col-md-12 program-middle-grid  equal padbtm">
              {/* <!--GRID START--> */}

              <div class="col-md-7">
                {/* <h3 style={{textAlign:"center",width:"100%"}}>Graph</h3> */}
                <div
                  class="tag-box tag-box-v6 box-shadow shadow-effect-2"
                  id="nullevery"
                >
                  <h3>Our Graduates Worldwide</h3>
                  {/* <PieChart /> */}
                  <PieExample />
                </div>
              </div>

              <div class="col-md-5 code code--small code--right" align="center">
                <div
                  class="inner-box inner-box-v6 box-shadow shadow-effect-2"
                  id="nullevery"
                  style={{ height: "350px" }}
                >
                  <h3 style={{ paddingBottom: "0px" }}>News Events</h3>
                  <NewsEvents />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="event">
        <div class="container">
          <div class="row">
            <div class="program-middle-grid   padbtm">
              {/* <!--GRID START--> */}

              <div class="col-md-8">
                {/* <h3 style={{textAlign:"center",width:"100%"}}>Graph</h3> */}
                <div
                  class="tag-box tag-box-v6 box-shadow shadow-effect-2"
                  id="nullevery"
                >
                  <HomeAlumni />
                  {/* <img src={Graph} style={{ width: "100%", height: "350px" }} /> */}
                </div>
              </div>

              <div class="col-md-4 code code--small code--right" align="center">
                <div>
                  <h4 className=" font">Contact </h4>
                  <div class="" id="nullevery">
                    <ContactBox />

                    {/* <img src={Admission} alt="Admission contact" /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!--GRID END--> */}
            <div class="col-md-12">
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>

      <ModelPopup
        state={open}
        size="md"
        status="info"
        close={handleClose}
        title="Model popup"
      ></ModelPopup>

      <section id="event">
        <div class="container">
          <div class="row">
            <div class=" program-middle-grid   padbtm">
              {/* <!--GRID START--> */}

              <div class="col-md-12 sm-paading slider-width">
                <div
                  class="tag-box tag-box-v6 box-shadow shadow-effect-2"
                  id="nullevery"
                >
                  <div className=" col-md-12">
                    <MoUPartners />
                  </div>

                  <div className=" col-md-12">
                    <h4 className=" font">Student Testimonials </h4>
                    <StudentTestimonials />
                  </div>
                </div>
              </div>

              {/* <div class="col-md-3 code code--small code--right" align="center">
                <div
                  class="inner-box inner-box-v6 box-shadow shadow-effect-2"
                  id="nullevery"
                >
                  <h6 className="font"> Contact</h6>
                  <div
                    className=" flex"
                    style={{ margin: "0px auto", justifyContent: "center" }}
                  >
                    <img src={Whatsapp} alt="whats app" />
                    <p className="contact-us-home">Whatsapp</p>
                  </div>

                  <div
                    className=" flex"
                    style={{ margin: "0px auto", justifyContent: "center" }}
                  >
                    <a
                      href="https://www.facebook.com/rvssulur.physiotherapy?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img src={FB} alt="whats app" />
                    </a>
                    <a
                      href="https://www.facebook.com/rvssulur.physiotherapy?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <p className="contact-us-home">FaceBook</p>
                    </a>
                  </div>

                  <div
                    className=" flex"
                    style={{ margin: "0px auto", justifyContent: "center" }}
                  >
                    <a
                      href="https://www.instagram.com/rvssulur.physiotherapy/?igsh=bzJxNWppcWxsdHRm"
                      target="_blank"
                    >
                      <img src={Instagram} alt="Instagram" />
                    </a>
                    <a
                      href="https://www.instagram.com/rvssulur.physiotherapy/?igsh=bzJxNWppcWxsdHRm"
                      target="_blank"
                    >
                      <p className="contact-us-home">Instagram</p>
                    </a>
                  </div>

                  <div
                    className=" flex"
                    style={{ margin: "0px auto", justifyContent: "center" }}
                  >
                    <a
                      href="https://www.youtube.com/@rvssulur-physiotherapy"
                      target="_blank"
                    >
                      <img src={Youtube} alt="Youtube" />
                    </a>
                    <a
                      href="https://www.youtube.com/@rvssulur-physiotherapy"
                      target="_blank"
                    >
                      <p className="contact-us-home">Youtube</p>
                    </a>
                  </div>

                  <img src={Admission} alt="Admission contact" />
                </div>
              </div> */}
            </div>
            {/* <!--GRID END--> */}
            <div class="col-md-12">
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
