import React, { useState } from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import PhysiotherapyBanner from "../assets/images/about-program/physio.png";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiOutlineChevronUp } from "react-icons/hi";
import CourseModelPopup from "../Components/Layout/CourseModelPopup";

import { HiHome } from "react-icons/hi";

export default function BPTProgram() {
  const [open, setOpen] = useState(false);
  const handleModelBox = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [forceRender, setForceRender] = useState(false);
  const [faq, setFaq] = useState([
    {
      id: 0,
      question:
        "Could you provide a detailed overview of the Bachelor of Physiotherapy (BPT) course?",
      amswer:
        "The BPT program offers a comprehensive four-year study, focusing on foundational physiotherapy knowledge and skills, preparing students to diagnose, prevent, and manage various conditions through evidence-based practice.",
      status: false,
    },
    {
      id: 1,
      question:
        "What is the duration of the Bachelor of Physiotherapy (BPT) program?",
      amswer:
        "The Bachelor of Physiotherapy (BPT) program spans four years of full-time study and a six-month internship in various departments, totaling four and a half years.",
      status: false,
    },
    {
      id: 2,
      question:
        "How does studying at RVS College of Physiotherapy prepare students for their future careers?",
      amswer:
        "Studying at RVS College of Physiotherapy, students with essential skills, clinical exposure, and a well-rounded approach ensure they become competent and compassionate physiotherapists ready for diverse healthcare challenges.",
      status: false,
    },
    {
      id: 3,
      question:
        "How does the campus ensure student comfort within campus life?",
      amswer:
        "	The eco-friendly environment fosters a peaceful atmosphere conducive to studying and learning. ",
      status: false,
    },
    {
      id: 4,
      question:
        "How does RVS College of Physiotherapy facilitate hands-on training for students?",
      amswer:
        "Students receive hands-on training during the third and final years at major MoU hospitals, followed by six months of CRRI after completing four years, as per the University guidelines.",
      status: false,
    },
  ]);

  const handleFaq = (v, i) => {
    faq?.forEach((el) => {
      if (el.id === v?.id) {
        el.status = true;
        console.log(v.status);
      } else {
        el.status = false;
      }
    });
    setForceRender(!forceRender);
    setFaq(faq);
    console.log(faq);
  };
  return (
    <div>
      <AboutProgramLayout>

        
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "23px",
                      paddingTop: "3px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Program
                </a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{
                  fontSize: "18px",
                  color: "rgb(34, 34, 34)",
                  fontWeight: "500",
                }}
              >
                Program Overview
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                BPT
              </li>
            </ol>
          </nav>
        </section>
        <div className=" row">
          <div className=" col-md-6">
          <h1 style={{marginBottom:"0px"}}>Bachelor of Physiotherapy</h1>
          </div>
          <div className=" col-md-6 Physiotherapy-page" style={{display:"flex"}} >
          <a
                  href="https://admissions.rvscas.ac.in?id=6246a5b9db7c39fc0e63e8ff&leadform=paid_leads"
                     target="_blank"
                     style={{margin:"0px",}}
                  class="md-trigger btn-default btn6"
                  data-modal="modal-8"
                >
                  Block Your Seat
                </a>
          </div>

        </div>
       

        <div
          class="about-middle-grid code code--small code--right"
          align="center"
        >
          {/* <img src={PhysiotherapyBanner} class="img-responsive" alt="" /> */}
        </div>

        <h2>About the Course</h2>

        <p  style={{lineHeight:"26px"}}>
          The Bachelor of Physiotherapy degree program is a four-year, full-time
          advanced program of study. After completing their fourth year of
          study, students complete a six-month internship in various
          departments. For this training, a minimum score of 50% is required in
          the mandatory assessment program to qualify for conferences, which
          means graduation.
        </p>

        <div
          style={{ marginBottom: "10px" }}
          class="col-md-12 col-sm-12 about-middle-grid border-enable "
        >
          <div class="">
            <h3>Course Objectives</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "26px",
                  }}
                >
                  Training competent, compassionate physiotherapists in
                  evidence-based practice to diagnose, prevent, and manage
                  various conditions.
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Designing and delivering physiotherapy interventions for
                  diverse clinical conditions across care settings.
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Enhancing research skills through critical appraisal of
                  literature and staying updated with modern developments.
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Fostering compassion and care for the physically challenged in
                  the institution and community.
                </div>
              </li>
            </ul>
            <br />
          </div>
        </div>

        <div style={{ paddingBottom: "18px" }} class="col-md-12 col-sm-12">
          <h3 style={{ marginTop: "13px" }}>What You Become</h3>
          <div className="col-md-6 col-sm-12 ">
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Physical Therapist
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Sports Physiotherapist
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Orthopedic Physiotherapist
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Neurological Physiotherapist
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Pediatric Physiotherapist
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-12 ">
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Geriatric Physiotherapist
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Cardiopulmonary Physiotherapist
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Private Practice
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Research and Teaching
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Occupational Health and Ergonomics
                </div>
              </li>
            </ul>
          </div>
        </div>

        <h3 style={{ paddingTop: "10px", marginTop: "10px" }}>Eligibility </h3>

        <p style={{lineHeight:"26px"}}>
          A. Pass mark in the higher secondary examination (12th) with the
          following subjects;
          <br />
          <span style={{ color: "#7f1010", fontSize: "16px" }}>
            Physics, Chemistry and Biology (or Botany and Zoology)
          </span>
        </p>

        <p style={{lineHeight:"26px"}}>
          B. For candidates who have studied abroad, they must meet the
          following criteria and have passed in the following subjects up to the
          12th standard level
          <br />
          <span style={{ color: "#7f1010", fontSize: "16px" }}>
            {" "}
            Physics, Chemistry, Biology (Botany or Zoology)and English
          </span>
        </p>

        <p style={{lineHeight:"26px"}}>
          C. For candidates who have passed the Senior Secondary School
          Examination of the National Open School, a minimum of 5 subjects is
          required, with one of the following group subjects
          <br />
          <span style={{ color: "#7f1010", fontSize: "16px" }}>
            English, Physics, Chemistry, Botany, and Zoology. English, Physics,
            Chemistry, Biology, and any other language.
          </span>
        </p>

        <p style={{lineHeight:"26px"}}>
          D. Minimum pass mark of 35% in{" "}
          <span style={{ color: "#7f1010", fontSize: "16px" }}>
            {" "}
            Physics, Chemistry, and Biology (or Botany and Zoology).
          </span>
        </p>

        <h3 style={{ paddingTop: "10px", marginTop: "10px" }}>
          Age Limit for Admission:{" "}
        </h3>

        <p style={{lineHeight:"26px"}}>
          A candidate must have reached the age of 17 at the time of admission
          or will attain that age on or before December 31st of the year they
          seek admission to the B.P.T degree course.
        </p>

        <h3 style={{ paddingTop: "10px", marginTop: "10px" }}>FAQ </h3>
        <div className=" col-md-12 content-middle-grid bggrey border">
          {faq?.map((v, i) => {
            return (
              <>
                <div
                  onClick={() => handleFaq(v, i)}
                  style={{ display: "flex", cursor: "pointer" }}
                  className="panel-custom"
                >
                  {v?.question}{" "}
                  <div>
                    {v?.status ? (
                      <HiOutlineChevronUp
                        className="primary"
                        style={{ marginTop: "3px", marginLeft: "3px" }}
                      />
                    ) : (
                      <HiOutlineChevronDown
                        className="primary"
                        style={{ marginTop: "3px", marginLeft: "3px" }}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={` ${
                    v?.status ? "show" : "hiddentag"
                  } panel-custom`}
                  style={{ background: "#fff" , lineHeight:"26px"}}
                >
                  {v?.amswer}
                </div>
              </>
            );
          })}
        </div>

        <CourseModelPopup
          state={open}
          size="md"
          status="info"
          close={handleClose}
          course="BPT"
          title="Model popup"
        ></CourseModelPopup>
     <div class="col-md-12"  style={{display:"flex", justifyContent:"center",alignItems:"center"}}  id="nullevery" >
          <a href="#" onClick={handleModelBox} class="md-trigger btn-default btn6" data-modal="modal-8">
            Download Brochure
          </a>
        </div>
      </AboutProgramLayout>
    </div>
  );
}
