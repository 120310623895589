import React from "react";
import InstitudeLayout from "../Components/Layout/Institude/InstitudeLayout";
import Trustee from "../assets/images/management/trustee.png";
import Chairman from "../assets/images/management/chairman.jpg";
import { HiHome } from "react-icons/hi";

export default function Management() {
  const handleSubmit = (e) => {};
  return (
    <div>
      <InstitudeLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Institute
                </a>
              </li>

              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                The Management
              </li>
            </ol>
          </nav>
        </section>
        <h1>RVS Educational Institutions </h1>

        <h2>Trust History</h2>
        <p style={{lineHeight:"26px"}}>
          RVS Educational Trust, established in 1984, stands as one of the
          largest educational institutions in South India, particularly in Tamil
          Nadu. With a steadfast dedication to the education sector, we've
          prioritized student satisfaction for more than 40 years of successful
          operation to enhance educational quality for society.
        </p>
        <h2>About Chairman</h2>
        <div class="col-md-12 col-sm-12" id="nullevery">
          <div class="col-md-9 col-sm-9" id="nullevery">
            {" "}
            <p  style={{lineHeight:"26px"}}>
              Dr. K. V. Kupusamy, our esteemed Chairman, is the visionary behind
              the establishment of over 80 prestigious institutions across Tamil
              Nadu. These institutions encompass diverse fields such as
              education, healthcare, and agriculture, reflecting his dedication
              and commitment to addressing societal challenges through these
              avenues.
            </p>
          </div>
          <div class="col-md-3 col-sm-3">
            <img src={Chairman} class="img-responsive" alt="" />
          </div>
        </div>

        <h2>About Managing Trustee</h2>
        <div class="col-md-12 col-sm-12" id="nullevery">
          <div class="col-md-9 col-sm-9" id="nullevery">
            {" "}
            <p  style={{lineHeight:"26px"}}>
              Dr. K. Senthil Ganesh has a keen focus on advancing education for
              the digital era. As the Managing Trustee of RVS Educational
              Institutions, he emphasizes the integration of digital
              technologies to enhance learning experiences and prepare students
              for the demands of the modern world.
            </p>
            <p  style={{lineHeight:"26px"}}>
              With 25 years of senior management experience spanning Strategy,
              Finance, and Marketing across various business functions, Dr.
              Senthil Ganesh initiated a transformative project in 2008.
              Acknowledging the importance of Digital Transformation and
              Disruption, he concentrated on RVS Colleges of Arts and Science,
              Health Sciences, and Ayurveda. This division witnessed remarkable
              growth, with student enrollment increasing from 2500 to over 7500
              within a decade, earning numerous accolades—all aligned with the
              vision of providing relevant education for the digital era.
            </p>
            <p  style={{lineHeight:"26px"}}>
              Beyond his role at RVS Educational Institutions, Dr. K. Senthil
              Ganesh also served as the Post-Chairman of CII (Confederation of
              Indian Industry), Coimbatore zone, a testament to his unwavering
              commitment to the development and progress of the region.
            </p>
          </div>
          <div class="col-md-3 col-sm-3">
            <img src={Trustee} class="img-responsive" alt="" />
          </div>
        </div>
      </InstitudeLayout>
    </div>
  );
}
