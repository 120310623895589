import InstitudeLayout from "../Components/Layout/Institude/InstitudeLayout";
import { HiHome } from "react-icons/hi";

export default function TheInstitute() {
  return (
    <>
      <InstitudeLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb code code--small code--right">
              <li className="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Institute
                </a>
              </li>

              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                The Institute
              </li>
            </ol>
          </nav>
        </section>
        <h1>About the College </h1>

        {/* <div
          className="about-middle-grid code code--small code--right"
          align="center"
        >
          <img
            src="../images/institute-banner.jpg"
            className="img-responsive"
            alt=""
          />
        </div> */}

        {/* <h2>About the College</h2> */}
        <p  style={{lineHeight:"26px"}}>
          RVS College of Physiotherapy, established in 1993, owes its inception
          to the visionary guidance of our esteemed Founder and Chairman, Dr.
          K.V. Kuppusamy. The college stands as a beacon of academic excellence,
          dedicated to education, research, and patient care. Nestled in a
          serene and eco-friendly environment, our institution is committed to
          fostering good health, promoting cultural understanding, nurturing
          social well-being, and encouraging the pursuit of knowledge.
        </p>

        <p  style={{lineHeight:"26px"}}>
          RVS College of Physiotherapy, in its Fourth decade, caters to aspiring
          physiotherapists&#39; educational needs. Affiliated with &#39;The
          Tamil Nadu Dr. M.G.R Medical University&#39; and recognized by
          &#39;The Indian Association of Physiotherapists,&#39; we pioneered the
          PhD program in Physiotherapy under &#39;The T.N Dr. M.G.R Medical
          University, Chennai. Our well-established Alumni Association,
          &#39;Physio Comrade,&#39; maintains a strong connection between our
          alumni and the institute, fostering networking opportunities and
          collaborative initiatives for professional growth.
        </p>

        <p  style={{lineHeight:"26px"}}>
          The core objective of the college is to equip students with profound
          knowledge in clinical, academic, and research excellence, fostering
          qualitative education and skills development for excellence. With a
          rich history, over 2000 students have graduated from our institution,
          making significant contributions worldwide and showcasing their
          dedication to both the college and the field of physiotherapy.
        </p>

        <p  style={{lineHeight:"26px"}}>
          The core objective of the college is to equip students with profound
          knowledge in clinical, academic, and research excellence, fostering
          qualitative education and skills development for excellence. With a
          rich history, over 2000 students have graduated from our institution,
          making significant contributions worldwide and showcasing their
          dedication to both the college and the field of physiotherapy.
        </p>

        <h2>Mission</h2>
        <p  style={{lineHeight:"26px"}}>
          We at RVS College of Physiotherapy will actively promote and preserve
          the higher values and ethics in education, healthcare, and research.
          Will pursue excellence in all these areas while continuously meeting
          the expectations of the people it serves without prejudice and in all
          fairness stay meaningful to the society. In the propagation of
          excellence in education to students and rehabilitation services in the
          healthcare industry and to serve humanity at large we will strive to
          remain socially meaningful.
        </p>

        <h2>Vision</h2>
        <p  style={{lineHeight:"26px"}}>
          To offer diverse educational programmes that facilitate the
          development of competent professionals and valuable citizens who
          demonstrate excellence in the respective discipline while serving
          locally and globally in areas of education, healthcare delivery and
          research.
        </p>
      </InstitudeLayout>
    </>
  );
}
