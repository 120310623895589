import React, { useState } from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import PhysiotherapyBanner from "../assets/images/about-program/physio.png";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiOutlineChevronUp } from "react-icons/hi";
import { HiHome } from "react-icons/hi";
import Orthopaedics from "../assets/images/MPT/Orthopaedics.png";
import Neurology from "../assets/images/MPT/Neurology.png";
import Physical from "../assets/images/MPT/physical-activity.png";


// import Model
// import ModelPopup from "../Components/Layout/PopUp"
import CourseModelPopup from "../Components/Layout/CourseModelPopup";
import { Link } from "react-router-dom";
export default function MPTProgram() {
  const [forceRender, setForceRender] = useState(false);
  const [faq, setFaq] = useState([
    {
      id: 0,
      question: "Could you provide a detailed overview of the MPT course?",
      amswer:
        "The MPT course involves advanced, specialized training in various domains of physiotherapy, allowing the students to develop expertise in specific areas of rehabilitation and treatment.",
      status: false,
    },
    {
      id: 1,
      question:
        "What is the duration of the Master of Physiotherapy (MPT) course?",
      amswer:
        "The MPT program spans a duration of two years, encompassing advanced theoretical learning and extensive clinical training across specialized areas like orthopedics, neurology and sports physiotherapy.",
      status: false,
    },
    {
      id: 2,
      question:
        "What areas of specialization are offered within the Master of Physiotherapy Program?",
      amswer:
        "Physiotherapy in Orthopaedics, Physiotherapy in Neurology, and Sports Physiotherapy are the specialized areas offered within the program.",
      status: false,
    },
    {
      id: 3,
      question:
        "How does completing the MPT program benefit students’ careers?",
      amswer:
        "The MPT program equips students with advanced skills to become licensed physical therapists, enabling diverse career opportunities in healthcare. Specializations in orthopedics, neurology, and sports physiotherapy offer focused career paths.",
      status: false,
    },
    {
      id: 4,
      question:
        "What faculty expertise is available in the Master of Physiotherapy program?",
      amswer:
        "The program features seasoned professionals with extensive practical knowledge, research expertise, and clinical experience, enhancing student learning through valuable mentorship and guidance.",
      status: false,
    },
  ]);

  const [open, setOpen] = useState(false);
  const handleModelBox = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFaq = (v, i) => {
    faq?.forEach((el) => {
      if (el.id === v?.id) {
        el.status = true;
        console.log(v.status);
      } else {
        el.status = false;
      }
    });
    setForceRender(!forceRender);
    setFaq(faq);
    console.log(faq);
  };
  return (
    <div>
      <AboutProgramLayout>

        
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "23px",
                      paddingTop: "3px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Program
                </a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{
                  fontSize: "18px",
                  color: "rgb(34, 34, 34)",
                  fontWeight: "500",
                }}
              >
                Program Overview
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                MPT
              </li>
            </ol>
          </nav>
        </section>

        <div className=" row">
          <div className=" col-md-6">
          <h1 style={{marginBottom:"0px"}}>Master of Physiotherapy
          </h1>
          </div>
          <div className=" col-md-6 Physiotherapy-page" style={{display:"flex"}} >
          <a href="#admission"   style={{margin:"0px",}}
              class="md-trigger btn-default btn6"
              data-modal="modal-8">

            
           
                 Block Your Seat
                 </a>
          {/* <a
                  href="#admission"
                     target="_blank"
                     style={{margin:"0px",}}
                  class="md-trigger btn-default btn6"
                  data-modal="modal-8"
                >
                  Block Your Seat
                </a> */}
          </div>

        </div>
        

    
        <div
          class="about-middle-grid code code--small code--right"
          align="center"
        >
          {/* <img src={PhysiotherapyBanner} class="img-responsive" alt="" /> */}
        </div>

        <h2>About the Course</h2>

        <p style={{lineHeight:"26px"}}>
          The Master of Physiotherapy (MPT) is an advanced master’s degree
          designed for individuals with a bachelor’s degree in physical therapy
          or a related field. This program combines advanced learning with
          clinical training to prepare graduates for roles as licensed physical
          therapists in health care settings. Upon successful completion, MPT
          graduates skillfully help patients return to mobility and function and
          provide intensive care for physical disabilities or injuries.
        </p>

        <div
          style={{ marginBottom: "24px" }}
          class="col-md-12 col-sm-12 about-middle-grid border-enable "
        >
          <div class="">
            <h3>Course Objectives</h3>
            <ul id="nullevery">
              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  To acquire knowledge in medical and physiotherapeutic sciences
                  related to specific specialties. To master the necessary
                  physiotherapy skills for specialized assessments using current
                  concepts in the field.
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  To design and execute suitable physiotherapeutic interventions
                  for clinical conditions within their specialty, encompassing
                  acute and chronic phases, critical care, institutional
                  settings, and independent practice.
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  To develop the ability to teach postgraduate and undergraduate
                  Physiotherapy students. To demonstrate administrative skills
                  and legislation applicable to compensate for the functional
                  disability and appropriate certification.
                </div>
              </li>

              <li style={{ display: "flex" }}>
                <div>
                  <HiOutlineChevronDoubleRight
                    className=" primary "
                    style={{ marginRight: "3px", marginTop: "3px" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#2b2b2b",
                    lineHeight: "1.3em",
                  }}
                >
                  Acquainting the students with the concept of quality care at
                  the institutional as well as the community levels.
                </div>
              </li>
            </ul>
            <br />
          </div>
        </div>

        <div id="admission" style={{padding:"10px"}}> </div>


        <div style={{ paddingBottom: "10px" }} class="">
          <h3 style={{ marginTop: "18px" }}>Specialization Offered</h3>

          <div className=" row">
            <div className=" col-md-4">
              <div
                style={{
                  height: "200px",
                  width: "200px",
                  margin: "0px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Orthopaedics}
                  alt="Orthopaedics"
                  style={{
                    height: "150px",
                    width: "150px",
                    margin: "0px auto",
                  }}
                />
              </div>
              <h4
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  paddingTop:"0px",
                  marginTop:"0px"
                }}
              >
                Physiotherapy in<br/> Orthopaedics
              </h4>
              {/* <a href="#" class="md-trigger btn-default btn6" data-modal="modal-8">Block Your Seat</a> */}

              <div class="col-md-12" id="nullevery"    style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <a
                  href="https://admissions.rvscas.ac.in/?id=624e802f16579ddcb665a2d5&leadform=paid_leads"
                     target="_blank"
                  class="md-trigger btn-default btn6"
                  data-modal="modal-8"
                >
                  Block Your Seat
                </a>
              </div>
            </div>

            <div className=" col-md-4">
              <div
                style={{
                  height: "200px",
                  width: "200px",
                  margin: "0px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Neurology}
                  alt="Neurology"
                  style={{
                    height: "150px",
                    width: "150px",
                    margin: "0px auto",
                  }}
                />
              </div>
              <h4
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                    paddingTop:"0px",
                  marginTop:"0px"
                }}
              >
                Physiotherapy  in<br/> Neurology
              </h4>
              {/* <a href="#" class="md-trigger btn-default btn6" data-modal="modal-8">Block Your Seat</a> */}

              <div
                class="col-md-12"
                id="nullevery"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://admissions.rvscas.ac.in/?id=624e804e16579ddcb665a2d7&leadform=paid_leads"
   target="_blank"
                  class="md-trigger btn-default btn6"
                  data-modal="modal-8"
                >
                  Block Your Seat
                </a>
              </div>
            </div>

            <div className=" col-md-4">
              <div
                style={{
                  height: "200px",
                  width: "200px",
                  margin: "0px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Physical}
                  alt="Physical"
                  style={{
                    height: "150px",
                    width: "150px",
                    margin: "0px auto",
                  }}
                />
              </div>
              <h4
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                    paddingTop:"0px",
                  marginTop:"0px"
                }}
              >
               Sports <br/> Physiotherapy

              </h4>
              {/* <a href="#" class="md-trigger btn-default btn6" data-modal="modal-8">Block Your Seat</a> */}

              <div class="col-md-12" id="nullevery"    style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <a
                  href="https://admissions.rvscas.ac.in?id=624e806416579ddcb665a2d9&leadform=paid_leads"
                  target="_blank"
                  class="md-trigger btn-default btn6"
                  data-modal="modal-8"
                >
                  Block Your Seat
                </a>
              </div>
            </div>
          </div>
       
        </div>

        <h3 style={{ paddingTop: "0px", marginTop: "10px" }}>
          Eligibility for MPT{" "}
        </h3>

        <p style={{lineHeight:"26px"}}>
          A. Candidates applying for admission to the Master of Physiotherapy
          program should have successfully completed the BPT degree examination
          from either "The Tamil Nadu Dr. M.G.R Medical University" or an
          equivalent examination recognized by the university's authority.
        </p>

        <p style={{lineHeight:"26px"}}>
          B. If candidates have passed the BPT examination at a university other
          than "The Tamil Nadu Dr. M.G.R Medical University," except for
          Annamalai University, they are required to obtain an eligibility
          certificate from this university.
        </p>

        <p style={{lineHeight:"26px"}}>
          C. To obtain the eligibility certificate, candidates must follow these
          steps
          <br />
          <b>Step 1 :</b> Remit the prescribed fees.
          <br />
          <b>Step 2 :</b> Submit the application form.
          <br />
          <b>Step 3 :</b> After obtaining the eligibility certificate, they can
          proceed with the application for admission.
        </p>

        <h3 style={{ paddingTop: "10px", marginTop: "10px" }}>FAQ </h3>
        <div className=" col-md-12 content-middle-grid bggrey border">
          {faq?.map((v, i) => {
            return (
              <>
                <div
                  onClick={() => handleFaq(v, i)}
                  style={{ display: "flex", cursor: "pointer" }}
                  className="panel-custom"
                >
                  {v?.question}{" "}
                  <div>
                    {v?.status ? (
                      <HiOutlineChevronUp
                        className="primary"
                        style={{ marginTop: "3px", marginLeft: "3px" }}
                      />
                    ) : (
                      <HiOutlineChevronDown
                        className="primary"
                        style={{ marginTop: "3px", marginLeft: "3px" }}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={` ${
                    v?.status ? "show" : "hiddentag"
                  } panel-custom`}
                  style={{ background: "#fff" ,lineHeight:"26px"}}
                >
                  {v?.amswer}
                </div>
              </>
            );
          })}
        </div>
        <CourseModelPopup
          state={open}
          size="md"
          status="info"
          close={handleClose}
          course="MPT"
          title="Model popup"
        ></CourseModelPopup>
        <div  >

        </div>
        <div class="col-md-12"  style={{display:"flex", justifyContent:"center",alignItems:"center"}}  id="nullevery" >
          <a href="#" onClick={handleModelBox} class="md-trigger btn-default btn6" data-modal="modal-8">
            Download Brochure
          </a>
        </div>
      </AboutProgramLayout>
    </div>
  );
}
