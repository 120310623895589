import React from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import { HiHome } from "react-icons/hi";
import Chairman from "../assets/images/management/chairman.jpg";
import Trustee from "../assets/images/management/trustee.png";
import Secretary from "../assets/images/management/secretary.png";
import Franklin from "../assets/images/management/Franklin.png";
import OthersLayout from "../Components/Layout/Others/OthersLayout";

export default function CarrerPathWay() {
  return (
    <div>
      <OthersLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb code code--small code--right">
              <li className="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                Others
                </a>
              </li>

              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Career Pathway
              </li>
            </ol>
          </nav>
        </section>

        <div>
          <h1 classNameName=" primary">Industry Connect</h1>
          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Employer Partnerships
          </h4>
          <p style={{lineHeight:"26px"}}>
            RVS College of Physiotherapy has forged strong partnerships with
            leading healthcare institutions and industry players, offering
            students exclusive access to real-world experiences and potential
            career opportunities upon graduation.
          </p>

          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Career Opportunities
          </h4>
          <p style={{lineHeight:"26px"}}>
            Our institution provides comprehensive career guidance and support,
            connecting students with diverse employment prospects in hospitals,
            rehabilitation centers, sports organizations, and academic
            institutions, ensuring a smooth transition from education to the
            professional world.
          </p>

          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Expert Speaker Series
          </h4>
          <p style={{lineHeight:"26px"}}>
            We host regular sessions featuring prominent experts and
            practitioners in the field of physiotherapy, providing students with
            invaluable insights, the latest industry trends, and practical
            knowledge, enhancing their learning experience.
          </p>

          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Alumni Networking Events
          </h4>
          <p style={{lineHeight:"26px"}}>
            RVS College of Physiotherapy, through its alumni association 'Physio
            Comrade,' orchestrates networking events and gatherings that
            facilitate connections between current students and accomplished
            alumni. This platform serves as a conduit for mentorship, career
            guidance, and the sharing of experiences, enriching the college's
            professional community.
          </p>

          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Research Collaboration
          </h4>
          <p style={{lineHeight:"26px"}}>
            Our institution encourages collaborative research initiatives with
            industry partners, promoting innovation and contributing to
            advancements in physiotherapy. This collaboration ensures a dynamic
            learning environment for students and faculty while fostering
            groundbreaking discoveries.
          </p>
        </div>

        <h3
          style={{
            textAlign: "center",
            color: "#8d0010",
            marginBottom: "20px",
          }}
        >
          Industry Advisory Board
        </h3>
        <div className=" row">
          <div className=" col-lg-3 col-md-6">
          <img src={Chairman} style={{ width: "100%" }} alt="Chairman"></img>
            <h3
              style={{
                color: "#8d0010",
                marginTop: "0px",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Dr. K.V. Kupusamy
            </h3>
            <h4
              style={{ color: "#222", marginTop: "0px", textAlign: "center" }}
            >
              Chairman - RVS Educational Trust
            </h4>
          </div>
          <div className=" col-lg-3 col-md-6">
          <img src={Trustee} style={{ width: "100%" }} alt="Chairman"></img>
            <h3
              style={{
                color: "#8d0010",
                marginTop: "0px",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Dr. K. Senthil Ganesh
            </h3>
            <h4
              style={{ color: "#222", marginTop: "0px", textAlign: "center" }}
            >
              Managing Trustee - RVS Educational Trust
            </h4>
          </div>
          <div className=" col-lg-3 col-md-6">
          <img src={Secretary} style={{ width: "100%" }} alt="Chairman"></img>
            <h3
              style={{
                color: "#8d0010",
                marginTop: "0px",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Mrs. Saramma Samuel
            </h3>
            <h4
              style={{ color: "#222", marginTop: "0px", textAlign: "center" }}
            >
              Secretary - RVS Educational Trust
            </h4>
          </div>
          <div className=" col-lg-3 col-md-6">
          <img src={Franklin} style={{ width: "100%" }} alt="Franklin"></img>
            <h3
              style={{
                color: "#8d0010",
                marginTop: "0px",
                marginBottom: "0px",
                textAlign: "center",
              }}
            >
              Dr. M.K. Franklin Shaju
            </h3>
            <h4
              style={{ color: "#222", marginTop: "0px", textAlign: "center" }}
            >
              Principal - RVS College of Physiotherapy
            </h4>
          </div>
        </div>
    

        <div>
          <h3 classNameName=" primary" style={{ color: "#8d0010" }}>
            Internship
          </h3>
          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Internship Opportunities
          </h4>
          <p style={{lineHeight:"26px"}}>
            At RVS College of Physiotherapy, we take immense pride in our
            comprehensive internship program, which plays a pivotal role in
            shaping our students into skilled and confident professionals. We
            have forged strong partnerships with some of the most esteemed
            hospitals and institutions in the field, providing an exceptional
            platform for our students to refine their skills and put their
            knowledge to the test.
          </p>

          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Top Hospital and Institution Collaborations
          </h4>
          <p style={{lineHeight:"26px"}}>
            Our academic collaborations have allowed our students to intern at
            some of the region's most prestigious hospitals and institutions.
            These partnerships offer invaluable experiences and exposure to a
            diverse range of cases, ensuring that our students are well-prepared
            to excel in the field of physiotherapy.
          </p>

          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Degree Certificate Criteria
          </h4>
          <p style={{lineHeight:"26px"}}>
            Our internship program is a valuable learning opportunity and a
            crucial step towards obtaining their degree certificate. We have
            implemented stringent standards that students are required to meet
            during their internship to guarantee they possess the knowledge and
            practical skills essential for excelling in their future careers.
            This commitment to excellence is a testament to the quality of
            education we offer.
          </p>

          <h4
            style={{ color: "#222", marginBottom: "0px", paddingBottom: "2px" }}
          >
            Real-Life Experience
          </h4>
          <p style={{lineHeight:"26px"}}>
            Throughout their time at RVS College of Physiotherapy, students have
            access to state-of-the-art facilities that provide hands-on
            training. These facilities are purposefully designed to simulate
            authentic clinical scenarios, providing students with a controlled
            environment in which to apply their acquired knowledge. This
            approach equips our students with the practical experience necessary
            to thrive in their professional careers.
          </p>
          <p style={{lineHeight:"26px"}}>
            Join us at RVS College of Physiotherapy to be part of a dynamic
            educational community that values not just academic excellence but
            also the practical skills and experiences that are crucial for a
            successful career in physiotherapy. Our internship opportunities are
            a testament to our commitment to your success.
          </p>
        </div>

        <div>
          <h3
            classNameName=" primary"
            style={{
              color: "#8d0010",
              paddingBottom: "0px",
              marginBottom: "0px",
            }}
          >
            MoU
          </h3>

          <h4
            style={{
              color: "#222",
              marginBottom: "0px",
              textAlign: "center",
              paddingBottom: "2px",
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            "Not only engaging within the campus."
          </h4>
          <h5 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Our memoranda of understanding with
          </h5>
          <div>
            <div classNameName=" col-md-6 col-sm-12">
              <ul id="nullevery">
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    Ramakrishna Mission Vivekananda University, Maruthi College
                    of Physical Education.
                  </div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>Ganga Hospitals, Coimbatore.</div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>K.M.C.H Hospital, Sulur.</div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>One Care Medical Centre</div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>Pondicherry Institute of Medical Sciences</div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>JSS Hospitals, Mysore.</div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>Amar Seva Sangam, Thenkasi.</div>
                </li>
              </ul>
            </div>

            <div classNameName=" col-md-6 col-sm-12">
              <ul id="nullevery">
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>Aristo medical center, Tirupur</div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>St Marys Hospital, Podanur</div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>BRJ Ortho Centre &amp; MAK Hospital, Coimbatore.</div>
                </li>

                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>Dr.Muthu’s Ortho Centre, Coimbatore.</div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>RS Hospital, Coimbatore.</div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    International Human Resource and Development Centre,
                    Periyanaickenpalayam
                  </div>
                </li>
                <li style={{ display: "flex" }}>
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className=" primary "
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginRight: "4px", marginTop: "3px" }}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      ></path>
                    </svg>
                  </div>
                  <div>ARS Hospitals, Tirupur</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </OthersLayout>
    </div>
  );
}
