import React, { useState } from "react";
import AlumniLayout from "../Components/Layout/Alumini/AlumniLayout";
import { HiHome } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoData from "../assets/images/home/no-data.png";
import { MdSearch } from "react-icons/md";

export default function AlumniDetails() {
  const [startYear, setStarYear] = useState(new Date());
  const [vaildStatus, setVaildStatus] = useState(false);
  const [jsonData, setJsonData] = useState([
    {
      _id: 0,
      name: "Hello0",
      batch: 2000,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/JUSTINPR.jpg",
    },
    {
      _id: 1,
      name: "Hello1",
      batch: 2002,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/MOHAMEDALIPP.jpg",
    },
    {
      _id: 2,
      name: "Hello2",
      batch: 2002,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/ABDULKALAMAZAD.jpg",
    },
    {
      _id: 3,
      name: "Hello3",
      batch: 2002,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/DEEPTHYSREEDHARANV.jpg",
    },
    {
      _id: 4,
      name: "Hello4",
      batch: 2000,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/NEETHULHAD.jpg",
    },
    {
      _id: 5,
      name: "Hello5",
      batch: 2004,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/RAGESH.jpg",
    },
    {
      _id: 6,
      name: "Hello6",
      batch: 2004,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/NITHIN.jpg",
    },
    {
      _id: 7,
      name: "Hello7",
      batch: 2000,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/BHARATHIVANAN.jpg",
    },
    {
      _id: 8,
      name: "Hello8",
      batch: 2000,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/JISHNU.jpg",
    },
    {
      _id: 9,
      name: "Hello9",
      batch: 2005,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/DENSIL.jpg",
    },
    {
      _id: 10,
      name: "Hello10",
      batch: 2002,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/SREEJA.jpg",
    },

    {
      _id: 12,
      name: "Hello12",
      batch: 2000,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/JAGADEESH.jpg",
    },
    {
      _id: 13,
      name: "Hello13",
      batch: 2000,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/JAGADEESH.jpg",
    },
    {
      _id: 14,
      name: "Hello14",
      batch: 2000,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/JIBIN.jpg",
    },
    {
      _id: 15,
      name: "Hello15",
      batch: 2000,
      img: "https://mba.rvscas.ac.in/aboutprograms/placements/MITHUN.jpg",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [firstData, setFirstData] = useState(jsonData);
  const [finalData, setFinalData] = useState();
  const [data, setData] = useState({
    batch: "",
  });

  const [errors, setErrors] = useState({
    batch: "",
  });

  const handleData = (date) => {
    setStarYear(date);
    console.log(date);
    var dateAllow = date.toDateString().split(" ").slice(1).join(" ");
    var dateYear = date.getFullYear();
    console.log(dateAllow, dateYear);
    var filtered = jsonData.filter((a) => a.batch == dateYear);
    setLoading(true);
    setInterval(() => {
      setLoading(false);
    }, 1000);
    setFirstData(filtered);
    setFinalData(filtered);
  };

  const handleClear = (e) => {
    setLoading(true);
    setInterval(() => {
      setLoading(false);
    }, 1000);
    e.preventDefault();
    setFirstData(jsonData);
  };

  const handleSubmit = (e) => {
    setVaildStatus(true);
    e.preventDefault();
  };
  return (
    <div>
      <AlumniLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  Alumni
                </a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Alumni
              </li>
            </ol>
          </nav>
        </section>

        <h3 class="my-1 mx-1" style={{ color: "black" }}>
          Alumni
        </h3>

        <div class="row">
          <div class="col-md-12">
            <form>
              <div class="form-group">
                <label for="Register Number">Select Batch</label>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      <DatePicker
                        class="datepicker-custom"
                        selected={startYear}
                        onChange={(date) => handleData(date)}
                        showYearPicker
                        dateFormat="yyyy"
                        withPortal
                      />
                      <div
                        style={{
                          height: "26px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0px -27px",
                          position: "relative",
                        }}
                      >
                        <MdSearch
                          style={{
                            fontSize: "20px",
                            margin: "2px 0px",
                            color: "#a70000",
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={(e) => handleClear(e)}
                        style={{ margin: "0px 40px" }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                {vaildStatus ? (
                  <span style={{ color: "red" }}>{errors?.batch}</span>
                ) : null}
              </div>

              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div class="loader"></div>{" "}
                </div>
              ) : null}

              {/* <div style={{ display: "flex", justifyContent: "start" }}>
                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ padding: "4px 40px" }}
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </button>
              </div> */}
            </form>
          </div>

          {loading ? null : (
            <div className=" row">
              <div className="col-md-12" style={{ marginTop: "20px" }}>
                {firstData?.map((v, i) => {
                  return (
                    <>
                      <div class="col-md-3 col-sm-4 about-middle-grid text-left">
                        <img src={v?.img} class="img-responsive" />
                        <div class="placementtitle">
                          RAJSEKHAR P<br />
                          <font class="plsize"> Agency Leader</font>
                        </div>
                        <div class="placementcompany">
                          IDBI Federal LIC, Coimbatore
                        </div>
                        <div class="textred">@ Rs. 5.0 LPA</div>
                        <br />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}

          <div>
            {loading ? null : (
              <p>
                {firstData?.length === 0 ? (
                  <div>
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize:"20px",
                        fontWeight:"600",
                        padding: "10px 10px 10px 20px",
                      }}
                    >
                      {" "}
                      Data No found...
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px 10px 10px 20px",
                      }}
                    >
                      <img src={NoData} alt="NoData" />
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </p>
            )}
          </div>

          <div></div>
        </div>
      </AlumniLayout>
    </div>
  );
}
