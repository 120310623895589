import InstitudeLayout from "../Components/Layout/Institude/InstitudeLayout";
import SecretaryImage from "../assets/images/management/secretary.png";
import { HiHome } from "react-icons/hi";

export default function Secretary() {
  return (
    <div>
      <InstitudeLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb code code--small code--right">
              <li className="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Institute
                </a>
              </li>

              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Secretary
              </li>
            </ol>
          </nav>
        </section>
        <h1>Prof. Saramma Samuel</h1>
        <h3>Secretary of RVS Educational Institutions</h3>
        <div className="col-md-12 col-sm-12" id="nullevery">
          <div className="col-md-9 col-sm-9" id="nullevery">
            {" "}
            <p style={{lineHeight:"26px"}}>
              She has a vision to lead the college to greater heights. She aims
              to embrace digital technology, incorporate trending curriculum,
              and establish strong industry connections. Passionately, she is
              dedicated to building and empowering faculty members to become
              leaders in education.
            </p>
          </div>
          <div className="col-md-3 col-sm-3">
            <img src={SecretaryImage} className="img-responsive" alt="" />
          </div>
        </div>
      </InstitudeLayout>
    </div>
  );
}
