import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "../../assets/images/home/physio-logo.png";
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/autoplay';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import AdmissionBanner from "../../assets/images/banner/banner.png";
import reunion from "../../assets/images/banner/reunion.png";

import ModelPopup from "../Layout/PopUp";
// import './styles.css';



// import required modules
// import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

import { Navigation } from "swiper/modules";

export default function Banner() {
  const [open, setOpen] = useState(false);
  const handleModelBox = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="sm-hidden top-header "></div>

      <ModelPopup
        state={open}
        size="md"
        status="info"
        close={handleClose}
        title="Model popup"
      ></ModelPopup>
      <section id="about" class="wow fadeInUp smBannerDeviceequal">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 equal padbtm " id="greybg">
              <div class="col-md-9 col-sm-12 overflow-hidden w-100 about-middle-grid code code--small code--right">
                <Swiper
                  navigation={true}
                  modules={[Navigation, Autoplay, Pagination]}
                  autoplay={{ delay: 3000 }}
                  breakpoints={{
                    300: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 50,
                    },
                  }}
                  className="mySwiper"
                >
                  <SwiperSlide className=" display">
                    <a href="https://admissions.rvscas.ac.in/" target="_blank">
                      <img
                        src={AdmissionBanner}
                        className="w-100 overflow-hidden "
                        style={{ overflow: "hidden" }}
                      />
                    </a>
                  </SwiperSlide>

                  <SwiperSlide className=" display">
                    <a href="/alumni/alumni" target="_blank">
                      <img
                        src={reunion}
                        className="w-100 overflow-hidden "
                        style={{ overflow: "hidden" }}
                      />
                    </a>
                  </SwiperSlide>

      

                
                </Swiper>
              </div>

              <div class="col-md-3 code code--small code--left hidden-sm">
                <div
                  class="tag-box tag-box-v6 box-shadow shadow-effect-2"
                  id="grad"
                >
                  <h4>Quick Links</h4>

                  <div class="text-center">
                    <a
                      href="#"
                      data-modal="modal-11"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      class="md-trigger btn-default btnyellow"
                      onClick={handleModelBox}
                    >
                      Download Brochure
                    </a>
                    {/* <!--<a href="https://docs.google.com/spreadsheets/d/1pWEVnvqC8nS85XBhFyLgK9QFUQIe11PDm1JHLJj7Wrs/edit?ts=5d89b6f4#gid=0" target="_blank" class="btn-default btn2">Academic Calender</a>--> */}
                    {/* <!-- <a href="https://pages.razorpay.com/pl_BwWHOlY2b0oj41/view" target="_blank"
                    class="btn-default btnyellow">Block your Seat</a> --> */}
                    <a
                      href="https://admissions.rvscas.ac.in"
                      target="_blank"
                      class="btn-default btnyellow"
                    >
                      Block your Seat
                    </a>
                    {/* <!--<a href="aboutprograms/placements.html" class="btn-default btn2">Placements</a>--> */}
                    {/* <!-- <a href="theinstitute/student-services.html" class="btn-default btnyellow">Student Services</a> --> */}
                    {/* <!-- <a href="aboutprograms/" class="btn-default btnyellow">Tech MBA</a> --> */}
                    {/* <!--<a href="theinstitute/campus.html" class="btn-default btn2">Infrastructure</a>--> */}
                    <a href="/others/gallery" class="btn-default btnyellow">
                      Gallery
                    </a>
                    <a
                      href="https://pay.webfront.in/webfront/#/merchantinfo/rathnavel-subramaniam-educational-trust/6331"
                      target="_blank"
                      class="btn-default btnyellow"
                    >
                      Online Payment
                    </a>
                    <a
                      href="/aboutprogram/contact"
                      class="btn-default btnyellow"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 hidden-lg hidden-md hidden-xs">
                <br />
              </div>
              <div class="col-sm-12 hidden-lg hidden-md  hidden-xs code code--small code--left">
                <div
                  class="tag-box tag-box-v6 box-shadow shadow-effect-2"
                  id="grad"
                >
                  <h4>Quick Links</h4>

                  <div class="col-sm-12" id="nullevery">
                    <div class="col-sm-6" id="nullevery">
                      <div class="text-center">
                        <a
                          href="theinstitute/student-services.html"
                          class="btn-default btnyellow"
                        >
                          Student Services
                        </a>
                        <a href="aboutprograms/" class="btn-default btnyellow">
                          Tech MBA
                        </a>
                        {/* <!-- <a href="theinstitute/campus.html" class="btn-default btnyellow">Infrastructure</a> --> */}
                        <a
                          href="https://pay.webfront.in/webfront/#/merchantinfo/rathnavel-subramaniam-educational-trust/6331"
                          target="_blank"
                          class="btn-default btnyellow"
                        >
                          Online Payment
                        </a>
                      </div>
                    </div>

                    <div class="col-sm-6" id="nullevery">
                      <div class="text-center">
                        <a
                          href="/others/placement"
                          class="btn-default btnyellow"
                        >
                          Placements
                        </a>
                        <a
                          href="/aboutprogram/admissions"
                          class="btn-default btnyellow"
                        >
                          Admissions
                        </a>
                        <a
                          href="/aboutprogram/contact"
                          class="btn-default btnyellow"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
