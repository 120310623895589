import React from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import Classroom from "../assets/images/campus/class-rooms.jpg";
import { HiHome } from "react-icons/hi";
import MedicalCamp from "../assets/images/gallery/Gallery001.png";
import Cultural from "../assets/images/gallery/Gallery010.png";
import transport from "../assets/images/gallery/transport.png";
import hostel from "../assets/images/gallery/hostel.png";

import sports from "../assets/images/gallery/sports.png";
import OthersLayout from "../Components/Layout/Others/OthersLayout";

export default function Campus() {
  return (
    <div>
      <OthersLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                Others
                </a>
              </li>

              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Campus
              </li>
            </ol>
          </nav>
        </section>
        <h1>Infrastructure</h1>
        <div class="col-md-12 col-sm-12" id="nullevery">
          <div class="col-md-6 col-sm-6" id="nullleft">
            <h3>Medical camps</h3>
            <img src={MedicalCamp} class="img-responsive" alt="" />
            <p>Regular free medical camps for an underserved population.</p>
          </div>
          <div class="col-md-6 col-sm-6" id="nullleft">
            <h3>Sports Club</h3>
            <img src={sports} class="img-responsive" alt="" />
            <p style={{lineHeight:"26px"}}>
              State-of-the-art track and field, football, basketball, and
              volleyball courts staffed by certified physical educators.
            </p>
          </div>
        </div>
        <div class="col-md-12 col-sm-12" id="nullevery">
          <div class="col-md-6 col-sm-6" id="nullleft">
            <h3>Cultural Club</h3>
            <img src={Cultural} class="img-responsive" alt="Cultural" />
            <p style={{lineHeight:"26px"}}>
              RVS Yuva platform provides students with an opportunity to nurture
              their artistic talents.
            </p>
          </div>
          <div class="col-md-6 col-sm-6" id="nullleft">
            <h3>Transportation</h3>
            <img src={transport} class="img-responsive" alt="transport" />
            <p style={{lineHeight:"26px"}}>
              We provide a comprehensive transportation service to ensure
              convenient and secure transportation, covering all major
              destinations.
            </p>
          </div>
        </div>
        <div class="col-md-12 col-sm-12" id="nullevery">
          <div class="col-md-6 col-sm-6" id="nullleft">
            <h3>Hostel</h3>
            <img src={hostel} class="img-responsive" alt="hostel" />
            <p style={{lineHeight:"26px"}}>
              We offer secure and separate AC and non-AC hostel rooms for both
              boys and girls. Hygienically prepared and aesthetically served
              vegetarian/non-vegetarian meals for hostel students.
            </p>
          </div>
        </div>
      </OthersLayout>
    </div>
  );
}
