import React from "react";
import NewsTicker from "react-advanced-news-ticker";

export default function NewsEvents() {
  return (
    <div>
      <NewsTicker
        rowHeight={118}
        maxRows={2}
        speed={600}
        // direction = {Directions.UP}
        duration={4000}
        autoStart={true}
        pauseOnHover={false}
        id="myId"
        className="myClassName1 myClassName2"
        style={{ marginTop: 34 }}
      >
        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Thur</div>
            <div>08 Feb</div>
          </div>

          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              "Introduction to Manual therapy concepts"
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>8/2/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Fri</div>
            <div>05 Jul</div>
          </div>

          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Reunion 1996 Batch
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>7/5/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Thu</div>
            <div>25 Jun</div>
          </div>

          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Blood donation camp
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>6/25/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Fri</div>
            <div>21 Jun</div>
          </div>

          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              International Yoga Day
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>6/21/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Sun</div>
            <div>16 Jun</div>
          </div>
          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Hypertension day
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>6/16/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Sat</div>
            <div>06 Apr</div>
          </div>
          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>workshop</div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>4/6/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Tue</div>
            <div>12 Mar</div>
          </div>
          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Women's day celebration
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>3/12/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Wed</div>
            <div>08 May</div>
          </div>
          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Research Advisory committee Meeting
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>5/8/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Sat</div>
            <div>27 Apr</div>
          </div>
          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Graduation ceremony
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              2024-4/27/2024
            </div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Sat</div>
            <div>06 Apr</div>
          </div>
          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Seminar on "Nuances of Research for Physiotherapy Professionals"
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>4/6/2024</div>
          </div>
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div style={{ width: "20%" }}>
            <div>Thu</div>
            <div>18 Jan</div>
          </div>
          <div
            style={{ width: "80%", textAlign: "left", paddingBottom: "20px" }}
          >
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Induction program for Diploma In Yoga for Youth Empowerment
            </div>
            <div style={{ color: "#a60d1f", textAlign: "left" }}>
              Confirmed-1/18/2024
            </div>
          </div>
        </div>
      </NewsTicker>
    </div>
  );
}
