import React from "react";
import Img from "../../assets/images/home/img.jpg";
import Physiotherapy from "../../assets/images/home/Physiotherapy.jpg";
import Physio from "../../assets/images/home/Physio.webp";

export default function HomeCard() {
  return (
    <div className="  container">
      <div class="col-md-12">
        <div class="col-md-3 col-sm-3 boxpad" id="nullevery">
          <div class="col-md-12 col-sm-12" id="nullevery">
            <a href="/aboutprogram/programoverview/bpt">
              <img
                src={Img}
                class="img-responsive"
                alt=""
                style={{
                  height: "200px",
                  width: "100%",
                  objectFit: "cover",
                  margin: "0px",
                }}
              />
            </a>
          </div>
          <div class="col-md-12 col-sm-12" id="nullevery">
            <a
              href="/aboutprogram/programoverview/bpt"
              style={{ color: "#fff" }}
            >
              <h5
                className=" text-center"
                style={{
                  color: "#fff",
                  fontSize: "20px",
                  paddingTop: "14px",
                  paddingBottom: "6px",
                }}
              >
                Bachelor of Physiotherapy
              </h5>
            </a>
          </div>
        </div>
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-3 col-sm-3 boxpad" id="nullevery">
          <div class="col-md-12 col-sm-12" id="nullevery">
            <a href="/aboutprogram/programoverview/mpt">
              <img
                src={Physiotherapy}
                class="img-responsive"
                alt=""
                style={{
                  height: "200px",
                  width: "100%",
                  objectFit: "cover",
                  margin: "0px",
                }}
              />
            </a>
          </div>
          <div class="col-md-12 col-sm-12" id="nullevery">
            <a
              style={{ color: "#fff", fontSize: "20px" }}
              href="/aboutprogram/programoverview/mpt"
            >
              <h5
                className=" text-center"
                style={{
                  color: "#fff",
                  fontSize: "20px",
                  paddingTop: "14px",
                  paddingBottom: "6px",
                }}
              >
                Master of Physiotherapy
              </h5>
            </a>
          </div>
        </div>
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-3 col-sm-3 boxpad" id="nullevery">
          <div class="col-md-12 col-sm-12" id="nullevery">
            <a href="/aboutprogram/programoverview/phd">
              <img
                src={Physio}
                class="img-responsive"
                alt=""
                style={{ height: "200px", width: "100%", margin: "0px", objectFit:"cover" }}
              />
            </a>
          </div>
          <div class="col-md-12 col-sm-12" id="nullevery">
            <a
              style={{ color: "#fff", fontSize: "20px" }}
              href="/aboutprogram/programoverview/phd"
            >
              <h5
                className=" text-center"
                style={{
                  color: "#fff",
                  fontSize: "20px",
                  paddingTop: "14px",
                  paddingBottom: "6px",
                }}
              >
                Doctor of Philosophy (PhD)
              </h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
