import { useState } from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiOutlineChevronUp } from "react-icons/hi";
import { HiHome } from "react-icons/hi";
import CourseModelPopup from "../Components/Layout/CourseModelPopup"

export default function PHDProgram() {
  const [open, setOpen] = useState(false);
  const handleModelBox = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [forceRender, setForceRender] = useState(false);
  const [faq, setFaq] = useState([
    {
      id: 0,
      question:
        "What is the typical duration of a Doctor of Philosophy (PhD) program in Physiotherapy?",
      amswer:
        "The program generally spans three years for full-time students and four years for those pursuing it on a part-time basis.",
      status: false,
    },
    {
      id: 1,
      question:
        "What career paths do PhD holders in Physiotherapy often pursue upon completion of their degree?",
      amswer:
        "Ph.D. graduates in Physiotherapy often embark on careers as educators, researchers, and clinical leaders. They significantly shape the profession's future, drive evidence-based practice, and innovate patient care.",
      status: false,
    },
    {
      id: 2,
      question:
        "What distinguishes a Doctor of Philosophy (PhD) in Physiotherapy from other academic degrees in the field?",
      amswer:
        "A Ph.D. in Physiotherapy represents the highest level of academic achievement, involving extensive research, completing a substantial dissertation, and acquiring advanced knowledge and research skills. This degree empowers graduates to contribute to the field’s practice and healthcare significantly.",
      status: false,
    },
  ]);

  const handleFaq = (v, i) => {
    faq?.forEach((el) => {
      if (el.id === v?.id) {
        el.status = true;
        console.log(v.status);
      } else {
        el.status = false;
      }
    });
    setForceRender(!forceRender);
    setFaq(faq);
    console.log(faq);
  };
  return (
    <div>
      <AboutProgramLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb code code--small code--right">
              <li className="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Program
                </a>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{
                  fontSize: "18px",
                  color: "rgb(34, 34, 34)",
                  fontWeight: "500",
                }}
              >
                Program Overview
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                PHD
              </li>
            </ol>
          </nav>
        </section>

        <div className=" row">
          <div className=" col-md-6">
          <h1 style={{marginBottom:"0px"}}>Doctor of Philosophy (PhD)</h1>
          </div>
          <div className=" col-md-6 Physiotherapy-page" style={{display:"flex"}} >
          <a
          onClick={handleModelBox}
                  // href="https://admissions.rvscas.ac.in?id=6246a5b9db7c39fc0e63e8ff&leadform=paid_leads"
                    //  target="_blank"
                     style={{margin:"0px",}}
                  class="md-trigger btn-default btn6"
                  data-modal="modal-8"
                >
                 Download Brochure
                </a>
          </div>

        </div>

        {/* <h1>Doctor of Philosophy (PhD)</h1> */}

        <div
          className="about-middle-grid code code--small code--right"
          align="center"
        >
          {/* <img src={PhysiotherapyBanner} className="img-responsive" alt="" /> */}
        </div>

        <h2>About the Course</h2>

        <p style={{lineHeight:"26px"}}>
          A Doctor of Philosophy (PhD) in Physiotherapy is the pinnacle of
          academic achievement in the field. It entails several years of
          intensive study, research, and the completion of a substantial
          dissertation. This degree equips graduates with advanced knowledge and
          research skills, enabling them to make meaningful contributions to
          physiotherapy practice and healthcare. PhD holders in physiotherapy
          often pursue careers as educators, researchers, and clinical leaders,
          helping shape the profession's future and improve patient care through
          evidence-based practices and innovative approaches.
        </p>

        <h3 style={{ paddingTop: "10px" }}>Course Duration </h3>

        <p>
          {" "}
          <HiOutlineChevronDoubleRight
            className=" primary"
            style={{ marginRight: "4px", marginLeft: "10px" }}
          />
          Full-Time - 3 Year
        </p>
        <p>
          {" "}
          <HiOutlineChevronDoubleRight
            className=" primary"
            style={{ marginRight: "4px", marginLeft: "10px" }}
          />
          Part-Time - 4 Year
        </p>

        <h3 style={{ paddingTop: "10px" }}>FAQ </h3>
        <div className=" col-md-12 content-middle-grid bggrey border">
          {faq?.map((v, i) => {
            return (
              <>
                <div
                  key={i}
                  onClick={() => handleFaq(v, i)}
                  style={{ display: "flex", cursor: "pointer" }}
                  className="panel-custom"
                >
                  {v?.question}{" "}
                  <div>
                    {v?.status ? (
                      <HiOutlineChevronUp
                        className="primary"
                        style={{ marginTop: "4px", marginLeft: "4px" }}
                      />
                    ) : (
                      <HiOutlineChevronDown
                        className="primary"
                        style={{ marginTop: "4px", marginLeft: "4px" }}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={` ${
                    v?.status ? "show" : "hiddentag"
                  } panel-custom`}
                  style={{ background: "#fff", lineHeight:"26px" }}
                >
                  {v?.amswer}
                </div>
              </>
            );
          })}
        </div>

        
        <CourseModelPopup
        state={open}
        size="md"
        status="info"
        close={handleClose}
        course="PHD"
        title="Model popup"
      ></CourseModelPopup>

<div class="col-md-12"  style={{display:"flex", justifyContent:"center",alignItems:"center"}}  id="nullevery" >
          <a href="#" onClick={handleModelBox} class="md-trigger btn-default btn6" data-modal="modal-8">
            Download Brochure
          </a>
        </div>
        {/* <div class="col-md-12" id="nullevery"  onClick={handleModelBox}><a href="#" class="md-trigger btn-default btn6" data-modal="modal-8">Download Brochure</a></div> */}
      
      </AboutProgramLayout>
    </div>
  );
}
