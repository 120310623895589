import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import { HiHome } from "react-icons/hi";
import OthersLayout from "../Components/Layout/Others/OthersLayout";

export default function ValueAddedCourse() {
  return (
    <div>
      <OthersLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb code code--small code--right">
              <li className="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                 Others
                </a>
              </li>
           
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Value Added Course
              </li>
            </ol>
          </nav>
        </section>
        <h1>Life as a Physiotherapy Student</h1>
        <h5 style={{ color: "#222" }}>Diploma in Yoga</h5>
        <p style={{lineHeight:"26px"}} className=" ParaStyle my-2 px-2">
          The Diploma in Yoga Course, provided by RVS College of Physiotherapy,
          is a one-year, part-time program affiliated with Vethathiri Maharishi
          Institute for Spiritual and Intuitional Education.
        </p>

        <h5 style={{ color: "#222" }}>Hands-on Training on Clinic</h5>
        <p  style={{lineHeight:"26px"}} className=" ParaStyle my-2 px-2">
          The college has established partnerships with leading institutions and
          hospitals, enriching students&#39; learning experiences by providing
          them with direct exposure to the field of physiotherapy.
        </p>
      </OthersLayout>
    </div>
  );
}
