import React, { useState } from "react";
import AlumniLayout from "../Components/Layout/Alumini/AlumniLayout";
import { HiHome } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

export default function AlumniEnquire() {
  const [sucessFully, setSucessFully] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startYear, setStarYear] = useState(new Date());
  const [vaild, setValid] = useState(true);
  const [vaildStatus, setVaildStatus] = useState(false);
  const [data, setData] = useState({
    name: "",
    dob: "",
    regno: "",
    email: "",
    batch: "",
    course: "",
    phone: "",
    enquire: "",
    reason: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    dob: "",
    regno: "",
    email: "",
    batch: "",
    course: "",
    phone: "",
    enquire: "",
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setData({
      ...data,
      [name]: value,
    });
    console.log(data);
  };

  if (vaild === true) {
    data.dob = startDate.toDateString().split(" ").slice(1).join(" ");
    data.batch = startDate.getFullYear();
    // data.batch = new Date(startDate) * 1000;
    let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    let Number = /^[6-9]\d{9}$/;

    if (data.name === "") {
      errors.name = "Enter the Name";
    } else if (data.name === undefined) {
      errors.name = "Enter the Name";
    } else {
      errors.name = true;
    }

    if (data.phone === "") {
      errors.phone = "Enter the phone";
    } else if (data.phone === undefined) {
      errors.phone = "Enter the phone";
    } else if (!Number.test(data.phone)) {
      errors.phone = "Enter Correct Number";
    } else if (data.phone.length < 10 || data.phone.length > 20) {
      errors.phone = "vaild required";
    } else {
      errors.phone = true;
    }

    if (data.email === "") {
      errors.email = "Enter the email";
    } else if (data.email === undefined) {
      errors.email = "Enter the email";
    } else if (!regex.test(data.email)) {
      errors.email = "vaild email required";
    } else {
      errors.email = true;
    }

    if (data.dob === "") {
      errors.dob = "Enter the Regno";
    } else if (data.dob === undefined) {
      errors.dob = "Enter the Regno";
    } else {
      errors.dob = true;
    }

    if (data.regno === "") {
      errors.regno = "Enter the Regno";
    } else if (data.regno === undefined) {
      errors.regno = "Enter the Regno";
    } else {
      errors.regno = true;
    }

    if (data.batch === "") {
      errors.batch = "Select the Batch";
    } else if (data.batch === undefined) {
      errors.batch = "Select the Batch";
    } else {
      errors.batch = true;
    }

    if (data.course === "") {
      errors.course = "Select the course";
    } else if (data.course === undefined) {
      errors.course = "Select the course";
    } else {
      errors.course = true;
    }

    if (data.enquire === "") {
      errors.enquire = "Select the Enquire";
    } else if (data.enquire === undefined) {
      errors.enquire = "Select the Enquire";
    } else {
      errors.enquire = true;
    }

    if (data.reason === "") {
      errors.reason = "Enter the reason";
    } else if (data.reason === undefined) {
      errors.reason = "Enter the reason";
    } else {
      errors.reason = true;
    }
  }

  const handleSubmit = (e) => {
    setVaildStatus(true);

    console.log(
      data,
      errors?.name === true && errors.dob === true,
      // &&
      // errors.regno === true &&
      // errors.email === true &&
      // errors.batch === true &&
      // errors.course === true &&
      // errors.phone === true &&
      // errors.enquire === true &&
      // errors.reason === true
    );
    e.preventDefault();
    if (
      errors?.name === true &&
      errors.dob === true &&
      errors.regno === true &&
      errors.email === true &&
      errors.batch === true &&
      errors.course === true &&
      errors.phone === true &&
      errors.enquire === true &&
      errors.reason === true
    ) {
      axios
        .post("http://localhost:7000/api/rvscptenquire", data)
        .then((res) => {
          document.getElementById("phone").value = "";
          document.getElementById("course").value = "";
          document.getElementById("reason").value = "";
          setErrors({
            name: "",
            dob: "",
            regno: "",
            email: "",
            batch: "",
            course: "",
            phone: "",
            enquire: "",
            reason: "",
          });
          setSucessFully(true);
          setVaildStatus(false);
          setTimeout(() => {
            setSucessFully(false);
          }, 3000);
          setData({
            name: "",
            dob: "",
            regno: "",
            email: "",
            batch: "",
            course: "",
            phone: "",
            enquire: "",
            reason: "",
          });
          document.getElementById("name").value = "";
          document.getElementById("regno").value = "";
          document.getElementById("email").value = "";
          document.getElementById("batch").value = "";
          document.getElementById("phone").value = "";
          document.getElementById("enquire").value = "";
        })
        .catch((err) => {});
    }
  };
  return (
    <div>
      <AlumniLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  Alumni
                </a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Alumni Enquire
              </li>
            </ol>
          </nav>
        </section>

        <h3 class="my-1 mx-1" style={{ color: "black" }}>
          Alumni Enquire
        </h3>

        <div class="row">
          <div class="col-md-6">
            <div>
              <form>
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    type="name"
                    class="form-control"
                    placeholder="Enter Name"
                    onChange={(e) => handleChange(e)}
                    id="name"
                    name="name"
                  />
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.name}</span>
                  ) : null}
                </div>

                <div class="form-group">
                  <label for="Register Number">Date of Birth</label>
                  <div style={{ width: "100%" }}>
                    <DatePicker
                      class="datepicker-custom"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      withPortal
                    />
                  </div>
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.dob}</span>
                  ) : null}
                </div>

                <div class="form-group">
                  <label for="Register Number">Register Number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Register Number"
                    onChange={(e) => handleChange(e)}
                    id="regno"
                    name="regno"
                  />
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.regno}</span>
                  ) : null}
                </div>

                <div class="form-group">
                  <label for="Register Number">Enter Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter the email"
                    onChange={(e) => handleChange(e)}
                    id="email"
                    name="email"
                  />
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.email}</span>
                  ) : null}
                </div>

                <div class="form-group">
                  <label for="Register Number">Select Batch</label>
                  <div style={{ width: "100%" }}>
                    <DatePicker
                      class="datepicker-custom"
                      selected={startYear}
                      onChange={(date) => setStarYear(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      withPortal
                    />
                  </div>
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.batch}</span>
                  ) : null}
                </div>

                <div class="form-group">
                  <label for="sel1" class="form-label">
                    Course
                  </label>
                  <div>
                    <select
                      name="course"
                      onChange={(e) => handleChange(e)}
                      class="form-control"
                      id="course"
                    >
                      <option>UG</option>
                      <option>PG</option>
                      <option>Ph.D</option>
                    </select>
                  </div>
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.course}</span>
                  ) : null}
                </div>

                <div class="form-group">
                  <label for="Mobile Number">Mobile Number</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Mobile Number"
                    onChange={(e) => handleChange(e)}
                    name="phone"
                    id="phone"
                  />
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.phone}</span>
                  ) : null}
                </div>

                <div class="form-group">
                  <label for="sel1" class="form-label">
                    Enquire
                  </label>
                  <div>
                    <select
                      name="enquire"
                      onChange={(e) => handleChange(e)}
                      class="form-control"
                      id="enquire"
                    >
                      <option>Certificate</option>
                      <option>Alumni Meet</option>
                      <option>Other</option>
                    </select>
                  </div>
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.enquire}</span>
                  ) : null}
                </div>

                <div class="form-group">
                  <label for="Reason">Reason </label>
                  <textarea
                    type="number"
                    class="form-control"
                    placeholder="Reason "
                    onChange={(e) => handleChange(e)}
                    name="reason"
                    id="reason"
                  />
                  {vaildStatus ? (
                    <span style={{ color: "red" }}>{errors?.reason}</span>
                  ) : null}
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ padding: "4px 40px" }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>

                {sucessFully ? (
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "6px",
                      fontSize: "16px",
                      color: "#2b4e03",
                      fontWeight: "800",
                    }}
                  >
                    Form Submitted Succesfully
                  </p>
                ) : (
                  <p></p>
                )}
              </form>
            </div>
          </div>
        </div>
      </AlumniLayout>
    </div>
  );
}
