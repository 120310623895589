import React from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import { HiHome } from "react-icons/hi";
import OthersLayout from "../Components/Layout/Others/OthersLayout";

export default function ContactUs() {
  return (
    <div>
      <OthersLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                Others
                </a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Contact us
              </li>
            </ol>
          </nav>
        </section>
        <h1> Contact Us</h1>
        <p>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Mobile No :
          </span>{" "}
          <a href="tel:+919566352677" style={{ fontSize: "16px" }}>
            9566352677
          </a>
          <br />
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            {" "}
            Phone No:
          </span>{" "}
          <a href="tel:0422-2687480" style={{ fontSize: "16px" }}>
            {" "}
            0422-2687480
          </a>
          ,{" "}
          <a href="tel:0422-2687421" style={{ fontSize: "15px" }}>
            2687421
          </a>
          <br />
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            {" "}
            Principal Mobile No:
          </span>{" "}
          <a href="tel:+919843671222" style={{ fontSize: "16px" }}>
            9843671222
          </a>
          <br />
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Email ID:{" "}
          </span>{" "}
          <a href="mailto:rvscpt@rvsgroup.com" style={{ fontSize: "16px" }}>
            rvscpt@rvsgroup.com
          </a>
        </p>

        <h3> LOCATION</h3>
        <p>
          RVS College of Physiotherapy
          <br />
          242-B, Trichy Road,
          <br />
          Sulur, Coimbatore, TamilNadu, India - 641 402
        </p>
      </OthersLayout>
    </div>
  );
}
