import { useState } from "react";
import ClinicLayout from "../Components/Layout/Clinic/ClinicLayout";
import { HiHome } from "react-icons/hi";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

export default function OnlineBooking() {
  const [startDate, setStartDate] = useState(new Date());
  const [vaild] = useState(true);
  const [vaildStatus, setVaildStatus] = useState(false);
  const [sucess, setSucess] = useState(false);

  const [data, setData] = useState({
    name: "",
    gender: "",
    age: "",
    email: "",
    mobile: "",
    session: "",
    appointdata: startDate,
    appointdataNum: "",
    reason: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    gender: "",
    age: "",
    email: "",
    mobile: "",
    session: "",
    appointdata: "",
    appointdataNum: "",
    reason: "",
  });

  if (vaild === true) {
    data.appointdata = startDate.toDateString().split(" ").slice(1).join(" ");
    data.appointdataNum = new Date(startDate) * 1000;
    // data.appointdata=  new Date(startDate) * 1000
    let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    let Number = /^[6-9]\d{9}$/;

    if (data.name === "") {
      errors.name = "required";
    } else if (data.name === undefined) {
      errors.name = "required";
    } else {
      errors.name = true;
    }

    if (data.mobile === "") {
      errors.mobile = "required";
    } else if (data.mobile === undefined) {
      errors.mobile = "required";
    } else if (!Number.test(data.mobile)) {
      errors.mobile = "Enter Correct Number";
    } else if (data.mobile.length < 10 || data.mobile.length > 20) {
      errors.mobile = "vaild required";
    } else {
      errors.mobile = true;
    }
    if (data.email === "") {
      errors.email = "required";
    } else if (data.email === undefined) {
      errors.email = "required";
    } else if (!regex.test(data.email)) {
      errors.email = "vaild email required";
    } else {
      errors.email = true;
    }

    if (data.age === "") {
      errors.age = "Enter the age";
    } else if (data.age === undefined) {
      errors.age = "Enter the age";
    } else {
      errors.age = true;
    }

    if (data.gender === "") {
      errors.gender = "Select the gender";
    } else if (data.gender === undefined) {
      errors.gender = "Select the gender";
    } else {
      errors.gender = true;
    }

    if (data.session === "") {
      errors.session = "Select the session";
    } else if (data.session === undefined) {
      errors.session = "Select the session";
    } else {
      errors.session = true;
    }

    if (data.reason === "") {
      errors.reason = "Enter the Reason";
    } else if (data.reason === undefined) {
      errors.reason = "Enter the Reason";
    } else {
      errors.reason = true;
    }

    if (data.appointdata === "") {
      errors.appointdata = "Select the date";
    } else if (data.appointdata === undefined) {
      errors.appointdata = "Select the date";
    } else {
      errors.appointdata = true;
    }
  }

  const handleSubmit = (e) => {
    setVaildStatus(true);
    e.preventDefault();
    console.log(data);

    if (
      errors?.name === true &&
      errors.gender === true &&
      errors.age === true &&
      errors.email === true &&
      errors?.mobile === true &&
      errors?.appointdata === true &&
      errors?.session === true &&
      errors?.reason === true
    ) {
      axios
        .post("http://localhost:7000/api/rvscptclinic", data)
        .then((res) => {
          setVaildStatus(false);
          document.getElementById("morning").checked = false;
          document.getElementById("afternoon").checked = false;
          document.getElementById("evening").checked = false;
          document.getElementById("female").checked = false;
          document.getElementById("male").checked = false;
          document.getElementById("reason").value = "";
          setData({
            name: "",
            gender: "",
            age: "",
            email: "",
            mobile: "",
            session: "",
            appointdata: "",
            appointdataNum: "",
            reason: "",
          });
          setErrors({
            name: "",
            gender: "",
            age: "",
            email: "",
            mobile: "",
            session: "",
            appointdata: "",
            appointdataNum: "",
            reason: "",
          });
          document.getElementById("name").value = "";
          document.getElementById("age").value = "";
          document.getElementById("email").value = "";
          document.getElementById("mobile").value = "";
          document.getElementsByName("gender").value = "";
          document.getElementById("appointdata").value = "";
          setSucess(true);
          setTimeout(() => {
            setSucess(false);
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setData({
      ...data,
      [name]: value,
    });
    console.log(data);
  };

  return (
    <div>
      <ClinicLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb code code--small code--right">
              <li className="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  Clinic
                </a>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                Online Booking
              </li>
            </ol>
          </nav>
        </section>

        <div>
          <h3 className="my-1 mx-1">Online Booking</h3>

          <div className="row">
            <div className="col-md-6">
              <div>
                <form>
                  <div className="form-group">
                    <label for="name">Name </label>
                    <input
                      type="name"
                      className="form-control"
                      placeholder="Enter Name"
                      id="name"
                      name="name"
                      onChange={(e) => handleChange(e)}
                    />
                    {vaildStatus ? (
                      <span style={{ color: "red" }}>{errors?.name}</span>
                    ) : null}
                  </div>

                  <div className="form-group" style={{ margin: "0px" }}>
                    <label for="Register Number" style={{ margin: 0 }}>
                      Gender
                    </label>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div className="form-check">
                        <label className="form-check-label">
                          <div style={{ display: "flex" }}>
                            <input
                              type="radio"
                              className="form-check-input gender"
                              name="gender"
                              value="male"
                              id="male"
                              onChange={(e) => handleChange(e)}
                            />
                            <div
                              style={{ margin: "4px 8px", paddingTop: "6px" }}
                            >
                              Male
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <div style={{ display: "flex" }}>
                            <input
                              type="radio"
                              className="form-check-input gender"
                              name="gender"
                              value="female"
                              id="female"
                              onChange={(e) => handleChange(e)}
                            />
                            <div
                              style={{ margin: "4px 8px", paddingTop: "6px" }}
                            >
                              Female
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    {vaildStatus ? (
                      <span style={{ color: "red" }}>{errors?.gender}</span>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label for="name">Age </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Age"
                      id="age"
                      name="age"
                      onChange={(e) => handleChange(e)}
                    />
                    {vaildStatus ? (
                      <span style={{ color: "red" }}>{errors?.age}</span>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label for="mobile">Mobile</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter the Mobile Number"
                      id="mobile"
                      name="mobile"
                      onChange={(e) => handleChange(e)}
                    />
                    {vaildStatus ? (
                      <span style={{ color: "red" }}>{errors?.mobile}</span>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label for="mobile">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter the Email"
                      id="email"
                      name="email"
                      onChange={(e) => handleChange(e)}
                    />
                    {vaildStatus ? (
                      <span style={{ color: "red" }}>{errors?.email}</span>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label for="Register Number">Appointment Date</label>
                    <div style={{ width: "100%" }}>
                      <DatePicker
                        className="datepicker-custom"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        minDate={new Date()}
                        name={"appointdata"}
                        value={data?.appointdata}
                        dropdownMode="select"
                        withPortal
                      />
                      {vaildStatus ? (
                        <span style={{ color: "red" }}>
                          {errors?.appointdata}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <label for="mobile">Reason</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter the Reason"
                      id="reason"
                      name="reason"
                      onChange={(e) => handleChange(e)}
                    />
                    {vaildStatus ? (
                      <span style={{ color: "red" }}>{errors?.reason}</span>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label for="Register Number">Session</label>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div className="form-check">
                        <label className="form-check-label">
                          <div style={{ display: "flex" }}>
                            <input
                              type="radio"
                              className="form-check-input session"
                              name="session"
                              id="morning"
                              onChange={(e) => handleChange(e)}
                              value="morning"
                            />
                            <div
                              style={{ margin: "4px 8px", paddingTop: "6px" }}
                            >
                              Morning
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <div style={{ display: "flex" }}>
                            <input
                              type="radio"
                              className="form-check-input session"
                              name="session"
                              id="afternoon"
                              onChange={(e) => handleChange(e)}
                              value="afternoon"
                            />
                            <div
                              style={{ margin: "4px 8px", paddingTop: "6px" }}
                            >
                              Afternoon
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label">
                          <div style={{ display: "flex" }}>
                            <input
                              type="radio"
                              className="form-check-input session"
                              name="session"
                              id="evening"
                              onChange={(e) => handleChange(e)}
                              value="evening"
                            />
                            <div
                              style={{ margin: "4px 8px", paddingTop: "6px" }}
                            >
                              Evening
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>

                    {vaildStatus ? (
                      <span style={{ color: "red" }}>{errors?.session}</span>
                    ) : null}
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ padding: "4px 40px" }}
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>

                  {sucess ? (
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "6px",
                        fontSize: "16px",
                        color: "#2b4e03",
                        fontWeight: "800",
                      }}
                    >
                      Form Submitted Succesfully
                    </p>
                  ) : (
                    <p></p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </ClinicLayout>
    </div>
  );
}
