import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Brochure from "../../assets/images/other-icons/brochure.png";
import axios from "axios";
import Hero from "../../assets/pdf/MBA HR.pdf"

export default function CourseModelPopup(props) {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    course: props.course,
  });
  const [sucessFully, setSucessFully] = useState(false);
  const { state, children, size, status, close } = props;

  const [popUpState, setPopUpStatus] = useState(state);

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    course: "",
  });

  const [vaild, setValid] = useState(true);
  const [vaildStatus, setVaildStatus] = useState(false);

  const [submitDisable, setSubmitDisable] = useState(false);

  const [messageStatus, setMessageStatus] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  // if(state===false){
  //   setData({

  //   })
  // }
  if (vaild === true) {
    let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    if (data.name === "") {
      errors.name = "required";
    } else if (data.name === undefined) {
      errors.name = "required";
    } else {
      errors.name = true;
    }

    if (data.phone === "") {
      errors.phone = "required";
    } else if (data.phone === undefined) {
      errors.phone = "required";
    } else if (data.phone.length < 10 || data.phone.length > 20) {
      errors.phone = "vaild required";
    } else {
      errors.phone = true;
    }

    if (data.email === "") {
      errors.email = "required";
    } else if (data.email === undefined) {
      errors.email = "required";
    } else if (!regex.test(data.email)) {
      errors.email = "vaild email required";
    } else {
      errors.email = true;
    }

    if (data.course === "") {
      errors.course = "required";
    } else if (data.course === undefined) {
      errors.course = "required";
    } else {
      errors.course = true;
    }
  }
  const handleClos = () => {};
  const handleClose = () => {
    close();
    errors.email = "";
    errors.name = "";
    errors.phone = "";
    data.name = "";
    data.email = "";
    data.phone = "";
    setSubmitDisable(false);
    setVaildStatus(false);

    console.log(data, errors);
  };

  const handleClick = (e) => {
    
    e.preventDefault();
    data.course = props.course;
    console.log(data, errors);
    if (
      errors?.name === true &&
      errors.email === true &&
      errors.phone === true &&
      errors.course === true
    ) {
      axios
        .post("http://localhost:7000/api/rvscptcoursebrochure", data)
        .then((res) => {
          setData({
            name: "",
            phone: "",
            email: "",
            course: "",
          });
          const brochureLink = Hero;
          const downloadLink = document.createElement('a');
          downloadLink.href = brochureLink;
          downloadLink.download = 'MBA in  Human Resources.pdf';
          // Appending the link to the document
          document.body.appendChild(downloadLink);
          // Triggering a click event on the link
          downloadLink.click();
          // Removing the link from the document
          document.body.removeChild(downloadLink);
          setSucessFully(true);
          setVaildStatus(false);
          setTimeout(() => {
            setSucessFully(false);
          }, 3000);
          setErrors({
            name: "",
            phone: "",
            email: "",
            course: "",
          });
          document.getElementById("name").value = "";
          document.getElementById("phone").value = "";
          document.getElementById("email").value = "";
          document.getElementById("course").value = "";
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setSubmitDisable(true);
    setVaildStatus(true);
  };
  return (
    <div>
      {state ? (
        <>
          <div className="model-popup">
            <div style={{ background: "#fff" }}>
              <div className={` model-box-header `}>
                <div className=" cross-icon" onClick={handleClose}>
                  <RxCross2 className={`cross`} />
                </div>
              </div>
              <div className="model-box-body ">
                {children}
                <h3 style={{ color: "#222", textAlign: "center" }}>
                  Register and Download Brochure
                </h3>
                <div className=" popup">
                  <div>
                    <img
                      src={Brochure}
                      alt="Brochure"
                      className=" Brochure"
                      style={{ height: "210px", objectFit: "cover" }}
                    />
                  </div>
                  <div className="formPlaced">
                    <form
                      id="brochureform"
                      name="brochureform"
                      method="post"
                      action=""
                      data-course="Home Page Overall Download Brouchure"
                    >
                      <div class="form-group">
                        <input
                          type="text"
                          name="name"
                          class="formbox form-control"
                          placeholder="Name"
                          required=""
                          id="name"
                          onChange={(e) => handleChange(e)}
                        />

                        {vaildStatus ? (
                          <div
                            style={{
                              color: "red",
                              textAlign: "left",
                              padding: "4px 6px",
                            }}
                          >
                            {errors.name}
                          </div>
                        ) : null}
                        <div id="nameError" class="error-message"></div>
                      </div>
                      <div class="form-group">
                        <input
                          type="email"
                          name="email"
                          class="formbox form-control"
                          placeholder="Email"
                          required=""
                          id="email"
                          onChange={(e) => handleChange(e)}
                        />
                        {vaildStatus ? (
                          <div
                            style={{
                              color: "red",
                              textAlign: "left",
                              padding: "4px 6px",
                            }}
                          >
                            {errors.email}
                          </div>
                        ) : null}

                        <div id="emailError" class="error-message"></div>
                      </div>
                      <div class="form-group">
                        <input
                          type="number"
                          name="phone"
                          class="formbox form-control"
                          placeholder="Number"
                          id="phone"
                          maxlength="10"
                          pattern="^\d{10}$"
                          required=""
                          onChange={(e) => handleChange(e)}
                        />
                        {vaildStatus ? (
                          <div
                            style={{
                              color: "red",
                              textAlign: "left",
                              padding: "4px 6px",
                            }}
                          >
                            {errors.phone}
                          </div>
                        ) : null}
                        <div id="mobileError" class="error-message"></div>
                      </div>

                      <div class="form-group">
                        <input
                          type="text"
                          name="course"
                          class="formbox form-control"
                          placeholder="Course"
                          id="course"
                          value={props.course}
                          disabled="true"
                          required=""
                          onChange={(e) => handleChange(e)}
                        />
                        {vaildStatus ? (
                          <div
                            style={{
                              color: "red",
                              textAlign: "left",
                              padding: "4px 6px",
                            }}
                          >
                            {errors.course}
                          </div>
                        ) : null}
                        <div id="mobileError" class="error-message"></div>
                      </div>

                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn-default btn3"
                          name="submit"
                          value="submit"
                          onClick={(e) => handleClick(e)}
                        >
                          Download
                        </button>
                        {sucessFully ? (
                          <p
                            style={{
                              textAlign: "center",
                              marginTop: "6px",
                              fontSize: "16px",
                              color: "#2b4e03",
                              fontWeight: "800",
                            }}
                          >
                            Form Submitted Succesfully
                          </p>
                        ) : (
                          <p></p>
                        )}
                        {/* <!-- <input type="submit" class="btn-default btn3" name="submit" id="submit" value="submit"> --> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
