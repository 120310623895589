import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HiChevronDoubleRight } from "react-icons/hi";

export default function OtherSideNavbar(props) {
  const [forceRender, setForceRender] = useState(false);

  const [navData, setnavData] = useState([
    {
      id: 0,
      nav_name: "Others",
      status: true,
      nav_link: "",
      data: [
        {
          sub_nav: "Career",
          sub_link: "/others/career",
        },
        {
          sub_nav: "Gallery",
          sub_link: "/others/gallery",
        },
        {
          sub_nav: "Placement",
          sub_link: "/others/placement",
        },
        {
          id: 4,
          sub_nav: "In Campus",
          status: false,
          nav_status: false,
          sub_link: "/others/campus",
        },
        {
          id: 2,
          sub_nav: "Value Added Courses",
          status: false,
          nav_status: false,
          sub_link: "/others/valueaddedcourse",
        },
        {
          id: 3,
          sub_nav: "Admission",
          status: false,
          nav_status: false,
          sub_link: "/others/admissions",
        },
    
        {
          id: 5,
          sub_nav: "Careers Pathway",
          status: false,
          nav_status: false,
          sub_link: "/others/careerpathway",
        },
        {
          id: 6,
          sub_nav: "Contact",
          status: false,
          nav_status: false,
          sub_link: "/others/contact",
        },
        
      ],
    },
  ]);
  const handleClick = (v, i) => {
    navData?.forEach((el) => {
      if (el?.id === v?.id) {
        el.status = true;
      } else {
        el.status = false;
      }
      setForceRender(!forceRender);
    });
  };
  return (
    <div>
      <div class="panel-group" id="accordion">
        {navData?.map((v, i) => {
          return (
            <>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title" onClick={() => handleClick(v, i)}>
                    <NavLink
                      to={v?.nav_link}
                      style={{ lineHeight: "10px", display: "flex" }}
                    >
                      <HiChevronDoubleRight
                        style={{ marginTop: "2px", color: "black" }}
                      />
                      <div
                        style={{
                          marginTop: "5px",
                          marginLeft: "2px",
                          color: "black",
                          fontSize: "20px",
                        }}
                        className="fontcolor"
                      >
                        {v?.nav_name}
                      </div>
                    </NavLink>
                  </h4>
                  <div className={` ${v?.status ? "show" : "hiddentag"} `}>
                    <div style={{ padding: "0px 10px" }}>
                      {v.data?.map((el) => {
                        return (
                          <>
                            <NavLink className="sidebar-li" to={el?.sub_link}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "30px",
                                  fontSize: "16px",
                                }}
                                onClick={() => handleClick(v, i)}
                              >
                                {el?.sub_nav}
                              </div>
                            </NavLink>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
