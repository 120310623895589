import React, { useEffect, useState } from "react";
import brochure from "../../assets/images/home/download-brochure.png";
import Logo from "../../assets/images/home/physio-logo.png";
import { HiChevronDoubleRight, HiOutlinePlus } from "react-icons/hi";
import { HiChevronDown } from "react-icons/hi";
import CPTLogo from "../../assets/images/home/logo.png";
import CPTLogoSm from "../../assets/images/home/logo-sm.png";
import { RiAlignLeft } from "react-icons/ri";
import { BsXLg } from "react-icons/bs";
import { RiAlignJustify } from "react-icons/ri";

import ModelPopup from "./PopUp";
import { BsX } from "react-icons/bs";

export default function Nav() {
  const [forceRender, setForceRender] = useState(false);
  const [hiddeNav, setHiddenNav] = useState(false);
  const [open, setOpen] = useState(false);
  const handleModelBox = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [navData, setnavData] = useState([
    {
      id: 0,
      nav_name: "About Program",
      status: false,
      nav_link: "",
      nav_data: [
        {
          title: "Physiotherapy Program",
          data: [
            {
              sub_nav: "B.P.T",
              sub_link: "/aboutprogram/programoverview/bpt",
            },
            {
              sub_nav: "M.P.T ",
              sub_link: "/aboutprogram/programoverview/mpt",
            },
            {
              sub_nav: "Ph.D ",
              sub_link: "/aboutprogram/programoverview/phd",
            },
          ],
        },
        {
          title: "Curriculum",
          data: [
            {
              sub_nav: "B.P.T Overview",
              sub_link: "/aboutprogram/curriculum/bpt",
            },
            {
              sub_nav: "M.P.T Overview",
              sub_link: "/aboutprogram/curriculum/mpt",
            },
          ],
        },
      ],
    },
    {
      id: 1,
      nav_name: "About",
      status: false,
      nav_link: "",
      nav_data: [
        {
          title: undefined,
          data: [
            {
              sub_nav: "The Institute",
              sub_link: "/institute/theinstitute",
            },
            {
              sub_nav: "The Management",
              sub_link: "/institute/management",
            },
            {
              sub_nav: "Secretary",
              sub_link: "/institute/secretary",
            },
            {
              sub_nav: "Principal",
              sub_link: "/institute/principal",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      nav_name: "Admissions",
      status: false,
      nav_link: "",
      nav_data: [
        {
          title: undefined,
          data: [
            {
              sub_nav: "Admissions",
              sub_link: "/others/admissions",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      nav_name: "Clinic",
      status: false,
      nav_link: "",
      nav_data: [
        {
          title: undefined,
          data: [
            {
              sub_nav: "Online Booking",
              sub_link: "/clinic/booking",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      nav_name: "Others",
      status: false,
      nav_link: "",
      nav_data: [
        {
          title: "Over all",
          data: [
            {
              sub_nav: "Career",
              sub_link: "/others/career",
            },
            {
              sub_nav: "Gallery",
              sub_link: "/others/gallery",
            },
            {
              sub_nav: "Placement",
              sub_link: "/others/placement",
            },
            {
              sub_nav: "Campus",
              sub_link: "/others/campus",
            },
            {
              sub_nav: "Value Added Course",
              sub_link: "/others/valueaddedcourse",
            },
            {
              sub_nav: "Admissions",
              sub_link: "/others/admissions",
            },
            {
              sub_nav: "Career Pathway",
              sub_link: "/others/careerpathway",
            },
            {
              sub_nav: "Contact",
              sub_link: "/others/contact",
            },
          ],
        },
        {
          title: "Alumni",
          data: [
            {
              sub_nav: "Register",
              sub_link: "/alumni/register",
            },
            {
              sub_nav: "Enquire",
              sub_link: "/alumni/enquire",
            },
            {
              sub_nav: "Alumni",
              sub_link: "/alumni/alumni",
            },
           
          ],
        },
      ],
    },
  ]);

  const handleClick = (v, i) => {
    navData?.forEach((el) => {
      if (el?.id === v?.id) {
        el.status = !el.status;
      } else {
        el.status = false;
      }
      // console.log(window.location.href.split("/"))
      setForceRender(!forceRender);
    });
  };

  const closeNavbar = () => {
    document.getElementById("mySidenav").style.width = "0";
    setTimeout(() => {
      setHiddenNav(false);
    }, 500);
  };

  const openNavbar = () => {
    setForceRender(!forceRender);
    // document.getElementById("navside").display = "none";
    document.getElementById("mySidenav").style.width = "100%";
    setHiddenNav(!hiddeNav);
  };
  return (
    <div>
      <div id="mySidenav" class="sidenav">
        <div
          onClick={() => closeNavbar()}
          style={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          className=" cancel"
        >
          {" "}
          <BsXLg
            className="cancel-icon primary  "
            // style={{ rotate: "rotate(45deg)" }}
          />
        </div>
        <div className=" mobile-navbar">
          {navData?.map((v, i) => {
            return (
              <>
                <div
                  className=" background-primary navbarprimary"
                  onClick={() => handleClick(v, i)}
                  style={{
                    display: "flex",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{ width: "90%", fontSize: "16px" }}
                    className=" heading"
                  >
                    {v?.nav_name}
                  </div>
                  <div style={{ width: "10%" }}>
                    <HiChevronDown
                      style={{
                        fontSize: "20px",
                        rotate: `${v?.status ? `180deg` : ``} `,
                      }}
                    />
                  </div>
                </div>
                <div
                  className={` ${v?.status ? "show " : "hiddentag"}  `}
                  style={{ background: "#272727" }}
                >
                  <div>{v?.title}</div>

                  {v?.nav_data?.map((el) => {
                    return (
                      <>
                        {el?.title === undefined ? null : (
                          <div
                            style={{
                              padding: "16px 20px",
                              background: "#333",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                            className=" primary"
                          >
                            {el?.title} 
                          </div>
                        )}

                        <div>
                          {el.data?.map((da) => {
                            return (
                              <>
                                <a href={da?.sub_link} style={{background: "#666666",}}>
                                  <div
                                    style={{
                                      background: "#666666",
                                      padding: "10px 10px",
                                      color: "#fff",
                                    }}
                                  >
                                    {da?.sub_nav}
                                  </div>
                                </a>
                              </>
                            );
                          })}
                          {/* {v?.nav_data?.data.map((da)=>{
                          return(
                            <>
                            {da?.sub_nav}
                            
                            </>
                          )
                        })} */}
                        </div>
                      </>
                    );
                  })}
                </div>
                <div></div>
              </>
            );
          })}
        </div>
      </div>

      {/* <div onClick={() => openNavbar()}>Hello</div> */}
      <div class="wsmenucontainer clearfix">
        <div class="overlapblackbg"> </div>

        <div class="header">
          <div class="wsmobileheader clearfix">
            <div
              id="wsnavtoggle"
              class="animated-arrow"
              style={{
                width: "100%",
                background: "#fff",
                margin: "0px",
                padding: "20px 10px",
              }}
            >
              <a href="/">
                <img
                  src={CPTLogo}
                  className=" sm-logo"
                  height="44"
                  style={{
                    padding: "0px 10px ",
                    margin: "0px auto",
                    width: "250px",
                    height: "44px",
                    cursor: "pointer",
                  }}
                />
              </a>
              <>
                <div
                  style={{ position: "absolute", top: "30%", right: "4%" }}
                  onClick={() => openNavbar()}
                >
                  {hiddeNav ? null : (
                    <RiAlignJustify
                      id="navside"
                      style={{ fontSize: "30px", color: "#a70000" }}
                    />
                  )}
                  {/* <span
                   
                  ></span> */}
                </div>
              </>
            </div>
            {/* <a class="smallogo" href="index.html">
            <img src="./images/RVSCAS.png" height="44" alt="" />
          </a> */}
            {/* <a class="callusicon" href="tel:+91 9894167898">
        <div class="fa fa-phone"></div>
      </a>  */}
          </div>

          <div class="wrapper clearfix bigmegamenu">
            <div class="logo clearfix code code--small code--left">
              <div class="link1">
                {/* <HiOutlineChevronRight /> */}
                {/* <NavLink to={"/institute/theinstitute"}>The Institute</NavLink> */}
              </div>
              {/* <NavLink to={"/institute/theinstitute"}>The Institute</NavLink> */}
              <a href="/">
                {" "}
                <img
                  src={CPTLogo}
                  alt=""
                  style={{ margin: "20px 0px;", padding: "10px 25px" }}
                />
              </a>
              {/* <a href="index.html" title="imsr logo"></a> */}
            </div>

            {/* <!--Main Menu HTML Code--> */}
            <nav class="wsmenu clearfix">
              <ul class="mobile-sub wsmenu-list">
                <li>
                  <a href="#" style={{ fontSize: "18px", paddingTop: "10px" }}>
                    Programs
                    <span class="arrow" style={{ marginTop: "10px" }}></span>
                  </a>
                  <div class="megamenu clearfix">
                    <ul class="col-lg-12 col-md-12 col-xs-12 link-list">
                      <li class="menuinnertitle"> Program</li>
                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/aboutprogram/programoverview/bpt"
                            >
                              BPT
                            </a>
                          </div>
                        </li>
                      </div>
                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/aboutprogram/programoverview/mpt"
                            >
                              MPT
                            </a>
                          </div>
                        </li>
                      </div>
                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/aboutprogram/programoverview/phd"
                            >
                              Ph.D
                            </a>
                          </div>
                        </li>
                      </div>
                    </ul>

                    <ul class="col-lg-12 col-md-12 col-xs-12 link-list">
                      <li class="menuinnertitle"> Curriculum</li>
                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/aboutprogram/curriculum/bpt"
                            >
                              BPT
                            </a>
                          </div>
                        </li>
                      </div>
                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/aboutprogram/curriculum/mpt"
                            >
                              MPT
                            </a>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>

                <li>
                  <a href="#" style={{ fontSize: "18px", paddingTop: "10px" }}>
                    About institute<span class="arrow"></span>
                  </a>
                  <div class="megamenu clearfix">
                    <ul class="col-lg-12 col-md-12 col-xs-12 link-list">
                      <li class="menuinnertitle"> About</li>
                      <div
                        class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/institute/theinstitute"
                            >
                              The Institute
                            </a>
                          </div>
                        </li>
                      </div>
                      <div
                        class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/institute/management"
                            >
                              The Management
                            </a>
                          </div>
                        </li>
                      </div>
                      <div
                        class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/institute/secretary"
                            >
                              Secretary
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/institute/principal"
                            >
                              Principal
                            </a>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>

                <li>
                  <a
                    href="/others/admissions"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    Admissions<span class="arrow"></span>
                  </a>
                  <div class="megamenu clearfix" style={{ paddingBottom: "" }}>
                    <ul class="col-lg-4 col-md-4 col-xs-12 link-list">
                      <p></p>
                      <li style={{}}>
                        <div style={{ display: "flex", marginTop: "2px" }}>
                          <HiChevronDoubleRight style={{ marginTop: "6px" }} />
                          <a
                            style={{ paddingLeft: "4px" }}
                            href="/others/admissions"
                          >
                            Admissions
                          </a>
                        </div>
                      </li>
                    </ul>
                    <ul class="col-lg-4 col-md-4 col-xs-12 link-list"></ul>
                  </div>
                </li>

                <li>
                  <a
                    href="/clinic/booking"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    Clinic <span class="arrow"></span>
                  </a>
                  <div class="megamenu clearfix" style={{ paddingBottom: "" }}>
                    <ul class="col-lg-4 col-md-4 col-xs-12 link-list">
                      <li style={{}}>
                        <div style={{ display: "flex", marginTop: "2px" }}>
                          <HiChevronDoubleRight style={{ marginTop: "6px" }} />
                          <a
                            style={{ paddingLeft: "4px" }}
                            href="/clinic/booking"
                          >
                            Clinic Booking
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <a href="#" style={{ fontSize: "18px", paddingTop: "10px" }}>
                    Others <span class="arrow"></span>
                  </a>
                  <div class="megamenu clearfix" style={{ paddingBottom: "" }}>
                    <ul class="col-lg-12 col-md-12 col-xs-12 link-list">
                      <li class="menuinnertitle">Overall</li>
                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/others/campus"
                            >
                              In Campus
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/others/valueaddedcourse"
                            >
                              Value added courses
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/others/careerpathway"
                            >
                              Career Pathway
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/others/contact"
                            >
                              Contact us
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/others/career"
                            >
                              Career
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/others/gallery"
                            >
                              Gallery
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/others/placement"
                            >
                              Placement
                            </a>
                          </div>
                        </li>
                      </div>
                    </ul>

                    <ul class="col-lg-12 col-md-12 col-xs-12 link-list">
                      <li class="menuinnertitle">Alumni</li>
                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/alumni/register"
                            >
                              Register
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/alumni/enquire"
                            >
                              Enquire
                            </a>
                          </div>
                        </li>
                      </div>

                      <div
                        class="col-lg-3 col-md-3 col-sm-3 col-xs-122"
                        id="nullevery"
                      >
                        <li style={{}}>
                          <div style={{ display: "flex", marginTop: "2px" }}>
                            <HiChevronDoubleRight
                              style={{ marginTop: "6px" }}
                            />
                            <a
                              style={{ paddingLeft: "4px" }}
                              href="/alumni/alumni"
                            >
                              Alumni
                            </a>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>

                <li>
                  <a
                    style={{ paddingTop: "10px" }}
                    href="#"
                    id="menuImage"
                    class="md-trigger"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    data-modal="modal-11"
                    onClick={handleModelBox}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img src={brochure} alt="" />
                  </a>{" "}
                  <ModelPopup
                    state={open}
                    size="md"
                    status="info"
                    close={handleClose}
                    title="Model popup"
                  ></ModelPopup>
                </li>

                {/* <!-- End Model Content--> */}

                <div class="md-overlay"></div>

                {/* <!--Model Content--> */}
              </ul>
              {/* <div id="nav-menu-text">PREPARE FOR DIGITAL FUTURE !</div> */}
            </nav>
            {/* <!--Menu HTML Code--> */}
          </div>
        </div>
      </div>
    </div>
  );
}
