import React from "react";
import AboutProgramLayout from "../Components/Layout/AboutProgram/AboutProgramLayout";
import { HiHome } from "react-icons/hi";

export default function BPTCurriculum() {
  return (
    <div>
      <AboutProgramLayout>
        <section id="breadcrumb">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb code code--small code--right">
              <li class="breadcrumb-item">
                <a href="/">
                  <HiHome
                    style={{
                      fontSize: "24px",
                      paddingTop: "4px",
                      margin: "-2px 0px",
                    }}
                  />
                </a>
              </li>
              <li class="breadcrumb-item">
                <a href="" style={{ fontSize: "18px", color: "#222" }}>
                  About Program
                </a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{
                  fontSize: "18px",
                  color: "rgb(34, 34, 34)",
                  fontWeight: "500",
                }}
              >
                Curriculum
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ fontSize: "18px" }}
              >
                BPT
              </li>
            </ol>
          </nav>
        </section>
        <div class="p-4 card">
          <div class="my-1 px-2">
            <h1>Bachelor of Physiotherapy Curriculum</h1>
            <div class=" mx-md-4 ">
              <h6 class="highlight">4 YEAR COURSE</h6>
              <h6 class="highlight">6 MONTHS INTERNSHIP</h6>
              <h6 class="highlight">MEDIUM OF INSTRUCTION: ENGLISH</h6>
            </div>
          </div>
          <div
            class="table-responsive "
            style={{ overflow: "auto", width: "96%" }}
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    width="20%"
                    className=" font-heading"
                    style={{ textAlign: "center" }}
                  >
                    SI.NO
                  </th>
                  <th
                    width="20%"
                    style={{ textAlign: "center", marginTop: "100px" }}
                  >
                    YEAR
                  </th>
                  <th width="60%">SUBJECT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td rowspan="7">
                    <div style={{ textAlign: "center", marginTop: "100px" }}>
                      <h6> Ⅰ YEAR</h6>
                    </div>
                  </td>
                  <td>Psychology / Sociology</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Anatomy</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Physiology, Applied Physiology</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Basic &amp; applied Physics for Physiotherapy</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Basic Nursing &amp; First Aid</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Orientation to physiotherapy</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Computer Science</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td rowspan="5">
                    <div style={{ textAlign: "center", marginTop: "100px" }}>
                      <h6>Ⅱ YEAR</h6>
                    </div>
                  </td>
                  <td>General Medicine / Surgery/ Paediatrics</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Biomechanics Applied Anatomy Kinesiology</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Exercise Therapy-I</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Exercise Therapy-II</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Microbiology / Pathology</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td rowspan="8">
                    <div style={{ textAlign: "center", marginTop: "100px" }}>
                      <h6> Ⅲ YEAR</h6>
                    </div>
                  </td>
                  <td>Electrotherapy – I (Low &amp; Medium Frequency)</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>
                    Electrotherapy – II ( High Frequency &amp; Actinotherapy)
                  </td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>Community Medicine</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>Clinical Orthopaedics</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>Clinical Neurology</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>Clinical Cardiorespiratory Diseases</td>
                </tr>
                <tr>
                  <td>19</td>
                  <td>Biostatistics and Research Methodology</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>Physical Modalities Maintenance</td>
                </tr>
                <tr>
                  <td>21</td>
                  <td rowspan="6">
                    <div style={{ textAlign: "center", marginTop: "100px" }}>
                      <h6> Ⅳ YEAR</h6>
                    </div>
                  </td>
                  <td>Physiotherapy in Orthopaedics</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>Physiotherapy in Neurology</td>
                </tr>
                <tr>
                  <td>23</td>
                  <td>Physiotherapy in Cardiorespiratory Conditons</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>Rehabilitation Medicine</td>
                </tr>
                <tr>
                  <td>25</td>
                  <td>Physical Education</td>
                </tr>
                <tr>
                  <td>26</td>
                  <td>Project Work/Case Study</td>
                </tr>{" "}
                <tr>
                  <td>27</td>
                  <td></td>
                  <td>CRRI – As per the University guidelines</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </AboutProgramLayout>
    </div>
  );
}
