import React from "react";
import { HiChevronRight } from "react-icons/hi";
import {
  RiFacebookBoxFill,
  RiTwitterXLine,
  RiYoutubeFill,
  RiInstagramFill,
  RiLinkedinBoxFill,
} from "react-icons/ri";

export default function Footer() {
  return (
    <div>
      {/* <!-- Footer --> */}
      <div class="footer w3ls">
        <div class="footer-2 w3ls">
          <div class="container">
            <div class="footer-main code code--small code--left">
              <div class="footer-top">
                <div class="col-md-3 col-sm-4 footer-middle-grid ftr-grid fg2">
                  <h3>Academics</h3>
                  <p style={{ paddingBottom: "1px", display: "flex" }}>
                    <HiChevronRight
                      style={{ fontSize: "18px", paddingTop: "4px" }}
                    />{" "}
                    <div style={{ fontSize: "15px" }}>
                      <a href="/aboutprogram/programoverview/bpt"> Program</a>
                    </div>
                  </p>
                  <p style={{ paddingBottom: "1px", display: "flex" }}>
                    <HiChevronRight
                      style={{ fontSize: "18px", paddingTop: "4px" }}
                    />{" "}
                    <div style={{ fontSize: "15px" }}>
                      <a href="/aboutprogram/curriculum/bpt"> Curriculum</a>
                    </div>
                  </p>
                </div>

                <div class="col-md-3 col-sm-4 footer-middle-grid ftr-grid fg2">
                  <h3>Institute</h3>
                  <p style={{ paddingBottom: "1px", display: "flex" }}>
                    <HiChevronRight
                      style={{ fontSize: "18px", paddingTop: "4px" }}
                    />{" "}
                    <div style={{ fontSize: "15px" }}>
                      <a href="/institute/theinstitute"> The Institute</a>
                    </div>
                  </p>
                  <p style={{ paddingBottom: "1px", display: "flex" }}>
                    <HiChevronRight
                      style={{ fontSize: "18px", paddingTop: "4px" }}
                    />{" "}
                    <div style={{ fontSize: "15px" }}>
                      <a href="/institute/management"> Management</a>
                    </div>
                  </p>
                  <p style={{ paddingBottom: "1px", display: "flex" }}>
                    <HiChevronRight
                      style={{ fontSize: "18px", paddingTop: "4px" }}
                    />{" "}
                    <div style={{ fontSize: "15px" }}>
                      <a href="/institute/secretary"> Secretary</a>
                    </div>
                  </p>
                  <p style={{ paddingBottom: "1px", display: "flex" }}>
                    <HiChevronRight
                      style={{ fontSize: "18px", paddingTop: "4px" }}
                    />{" "}
                    <div style={{ fontSize: "15px" }}>
                      <a href="/institute/principal"> Principal</a>
                    </div>
                  </p>
                </div>

                <div class="col-md-3 col-sm-4 footer-middle-grid ftr-grid fg2">
                  <h3>Contact Us</h3>
                  <p className="fontsize">
                    RVS College of Physiotherapy
                    <br />
                    242-B, Trichy Road,
                    <br />
                    Sulur, Coimbatore, TamilNadu, India - 641 402
                  </p>
                  <p>
                    Mobile:{" "}
                    <span>
                      <a href="tel:9095692229">+91 90956 92229</a>
                    </span>{" "}
                  </p>
                </div>

                <div class="col-md-3 ftr-grid fg2">
                  <h3>Keep Connected</h3>
                  <div style={{ display: "flex" }}>
                    <a
                      href=" https://www.facebook.com/rvssulur.physiotherapy?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <div className=" social-mdeia">
                        <RiFacebookBoxFill style={{ fontSize: "16px" }} />
                      </div>{" "}
                    </a>

                    <a
                      href=" https://youtube.com/@rvssulur-physiotherapy?si=IMzHNo6RFxdKGKTI"
                      target="_blank"
                    >
                      <div className=" social-mdeia">
                        <RiYoutubeFill style={{ fontSize: "16px" }} />
                      </div>
                    </a>

                    <a
                      href="https://www.instagram.com/rvssulur.physiotherapy?igsh=bzJxNWppcWxsdHRm"
                      target="_blank"
                    >
                      <div className=" social-mdeia">
                        <RiInstagramFill style={{ fontSize: "16px" }} />
                      </div>
                    </a>

                    <a
                      href=" https://www.linkedin.com/company/rvssulur-physiotherapy/"
                      target="_blank"
                    >
                      <div className=" social-mdeia">
                        <RiLinkedinBoxFill style={{ fontSize: "16px" }} />
                      </div>
                    </a>
                  </div>
                </div>

                <div class="clearfix"> </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyrights">
          <p style={{ fontSize: "15px" }}>
            &copy;
            <script>document.write(new Date().getFullYear())</script> 2024 RVS
            COLLEGE OF PHYSIOTHERAPY.All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}
