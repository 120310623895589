import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import "./assets/css/Nav.css";
import "./assets/css/style.css";
import "./assets/css/demo.css";
import "./assets/css/table.css";
import "./assets/css/webslidemenu.css";
import "./assets/css/custom.css";
import "./assets/css/model-popup.css";

import "./index.css";
import TheInstitute from "./Pages/TheInstitute";
import Nav from "./Components/Layout/Nav";
import Footer from "./Components/Layout/Footer";
import Management from "./Pages/Management";
import Secretary from "./Pages/Secretary";
import Principal from "./Pages/Principal";
import BPTProgram from "./Pages/BPTProgram";
import MPTProgram from "./Pages/MPTProgram";
import PHDProgram from "./Pages/PHDProgram";
// import { AboutProgramContext } from "./Context/AboutProgramContext";
import BPTCurriculum from "./Pages/BPTCurriculum";
import MPTCurriculum from "./Pages/MPTCurriculum";
import ValueAddedCourse from "./Pages/ValueAddedCourse";
import Admission from "./Pages/Admission";
import Campus from "./Pages/Campus";
import Careers from "./Pages/Careers";
// import AlumniLogin from "./Pages/AlumniLogin";
import Regesiter from "./Pages/Register";
import Dashboard from "./Pages/Dashboard";
import OnlineBooking from "./Pages/OnlineBooking";
import CarrerPathWay from "./Pages/CarrerPathWay";
import Placements from "./Pages/Placements";
import StudentActivity from "./Pages/StudentActivity";
import Gallery from "./Pages/Gallery";
import AlumniEnquire from "./Pages/AlumniEnquire";
import AlumniDetails from "./Pages/AlumniDetails";
import ContactUs from "./Pages/ContactUs";
// import Home from './Pages/Home';

export default function App() {
  return (
    <div>
      {/* <AboutProgramContext.Provider> */}
      <Nav />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/institute/theinstitute" element={<TheInstitute />} />
          <Route path="/institute/management" element={<Management />} />
          <Route path="/institute/secretary" element={<Secretary />} />
          <Route path="/institute/principal" element={<Principal />} />
          <Route
            path="/aboutprogram/programoverview/bpt"
            element={<BPTProgram />}
          />
          <Route
            path="/aboutprogram/programoverview/mpt"
            element={<MPTProgram />}
          />
          <Route
            path="/aboutprogram/programoverview/phd"
            element={<PHDProgram />}
          />
          <Route
            path="/aboutprogram/curriculum/bpt"
            element={<BPTCurriculum />}
          />
          <Route
            path="/aboutprogram/curriculum/mpt"
            element={<MPTCurriculum />}
          />

          {/* <Route path="/alumni/login" element={<AlumniLogin />} /> */}
          <Route path="/alumni/register" element={<Regesiter />} />
          <Route path="/alumni/enquire" element={<AlumniEnquire />} />
          <Route path="/alumni/alumni" element={<AlumniDetails />} />
          <Route path="/clinic/booking" element={<OnlineBooking />} />
          <Route path="/others/career" element={<Careers />} />
          <Route path="/others/placement" element={<Placements />} />
          <Route path="/others/gallery" element={<Gallery />} />
          <Route
            path="/others/students-activity"
            element={<StudentActivity />}
          />
          <Route
            path="/others/valueaddedcourse"
            element={<ValueAddedCourse />}
          />
          <Route path="/others/admissions" element={<Admission />} />
          <Route path="/others/contact" element={<ContactUs />} />
          <Route path="/others/campus" element={<Campus />} />
          <Route path="/others/careerpathway" element={<CarrerPathWay />} />
        </Routes>
      </BrowserRouter>
      <Footer />

      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" exact element={<Dashboard />} />
        </Routes>
      </BrowserRouter>

      {/* </AboutProgramContext.Provider> */}
    </div>
  );
}
