import React from "react";
import { RiFacebookBoxFill } from "react-icons/ri";
import Whatsapp from "../../assets/images/home/whatsup.png";
import Youtube from "../../assets/images/home/youtube.png";
import FB from "../../assets/images/home/fb.png";
import Instagram from "../../assets/images/home/instagram.png";

export default function ContactBox() {
  return (
    <div>
      <div
        class="inner-box inner-box-v6 box-shadow shadow-effect-2"
        id="nullevery"
        style={{ width: "100%",height:"290px", padding: "20px" }}
      >
        <div className=" social-media">
          <a
            href="https://www.facebook.com/rvssulur.physiotherapy?mibextid=ZbWKwL"
            target="_blank"
          >
            {/* <RiFacebookBoxFill style={{color:"#222", fontSize:"30px"}}/> */}
            <img src={FB} alt="Facebook" className=" social-img" />
          </a>
          <a
            href="https://www.facebook.com/rvssulur.physiotherapy?mibextid=ZbWKwL"
            target="_blank"
          >
            <p className=" social-text">Facebook</p>
          </a>
        </div>

        <div className=" social-media">
          <img src={Whatsapp} alt="Whatsapp" className=" social-img" />

          <p className=" social-text">Whats app</p>
        </div>

        <div className=" social-media">
        <a
                      href="https://www.youtube.com/@rvssulur-physiotherapy"
                      target="_blank"
                    >
          <img src={Youtube} alt="Youtube" className=" social-img" />
          </a>
          <a
                      href="https://www.youtube.com/@rvssulur-physiotherapy"
                      target="_blank"
                    >
          <p className=" social-text">Youtube</p>
          </a>
        </div>

        <div className=" social-media">
        <a
                      href="https://www.instagram.com/rvssulur.physiotherapy/?igsh=bzJxNWppcWxsdHRm"
                      target="_blank"
                    >
          <img src={Instagram} alt="Instagram" className=" social-img" />
</a>

<a
                      href="https://www.instagram.com/rvssulur.physiotherapy/?igsh=bzJxNWppcWxsdHRm"
                      target="_blank"
                    >
          <p className=" social-text">Instagram</p>
          </a>
        </div>
      </div>
    </div>
  );
}
