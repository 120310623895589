import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import profile from "../../assets/images/home/profile.png";
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/autoplay';
// import Ramakrishna from "../../assets/images/mou-partners/Ramakrishna.png";

// import OnecareMedical from "../../assets/images/mou-partners/One-care-Medical.png";
// import Pondicherry from "../../assets/images/mou-partners/Pondicherry.png";
import Amar from "../../assets/images/mou-partners/Amar.jpg";
import BRJ from "../../assets/images/mou-partners/BRJ.jpg";
import Muthu from "../../assets/images/mou-partners/Dr. Muthu.jpg";
import Ganga from "../../assets/images/mou-partners/Ganga.jpg";
import JSSHospital from "../../assets/images/mou-partners/JSS Hospital.jpg";
import OneCare from "../../assets/images/mou-partners/One Care.jpg";
import Pondicherry from "../../assets/images/mou-partners/Pondicherry.jpg";
import Ramakrishna from "../../assets/images/mou-partners/Ramakrishna.jpg";
import RSHospital from "../../assets/images/mou-partners/RS Hospital.jpg";


import KMCH from "../../assets/images/mou-partners/KMCH.jpg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import AdmissionBanner from "../../assets/images/banner/banner.png";
// import './styles.css';

// import required modules
import { Navigation } from "swiper/modules";

export default function MoUPartners() {
  return (
    <div>

      <h4 className=" font">MOU Partners</h4>
      <div>
        <Swiper
          navigation={true}
          modules={[Navigation, Autoplay, Pagination]}
          autoplay={{ delay: 700 }}
          breakpoints={{
            300: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2.2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          style={{ margin: "0px" }}
          className="mySwiper"
        >
          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Amar}
              alt="Amar"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>

          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={BRJ}
              alt="BRJ"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>

          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Muthu}
              alt="Muthu"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>

          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Ganga}
              alt="Ganga"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>

          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={JSSHospital}
              alt="JSS Hospital"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>

          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={OneCare}
              alt="One Care Hospital"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>

          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Pondicherry}
              alt="Pondicherry"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>

          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Ramakrishna}
              alt="Ramakrishna"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>


          <SwiperSlide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={RSHospital}
              alt="RS Hospital"
              style={{ margin: "0px auto", height: "150px" }}
            />
          </SwiperSlide>
        </Swiper>

        <div align="center" style={{ paddingBottom: "30px" }}>
          {/* <p>
            <a href="/others/placement" class="btn-default btn6  font">
              View More
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
}
