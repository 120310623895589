import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "../../assets/images/home/physio-logo.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import AdmissionBanner from "../../assets/images/banner/banner.png";
// import './styles.css';
import quote from "../../assets/images/home/quote.png";
// import required modules
import { Navigation } from "swiper/modules";

export default function StudentTestimonials() {
  return (
    <div>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
        style={{ margin: "0px" }}
        className="mySwiper"
      >
        <SwiperSlide className=" display" style={{ margin: "0px" }}>
          <div className="col-md-12 bggrey">
            <div className="student-testimonials-top ">
              {/* <img src={quote} alt="quote" style={{transform:"rotate(180deg)"}} /> */}
            </div>
            <div>
              <p
                className=" font testimonials"
                style={{
                  textAlign: "center",
                  width: "90%",
                  margin: "0px auto",
                  fontWeight: "600",
                  fontStyle: "italic",
                }}
              >
                <span style={{ fontSize: "50px", color: "#a60d1e" }}>"</span>My
                alma mater (RVS College of Physiotherapy) has been an integral
                part of shaping me into who I am today. It has given me
                cherished academic and personal moments to build my confidence
                in pursuing my career.
                <br />
              </p>

              <p
                className=" font"
                style={{
                  textAlign: "center",
                  width: "90%",
                  margin: "0px auto",
                  fontWeight: "600",
                  fontStyle: "italic",
                }}
              >
                We overcame challenges and learned medical concepts
                holistically; the institution ensured we had well-trained
                Internship opportunities and extracurricular activities, which
                were filled with fun and frolic that made memories of a
                lifetime.
                <br />
              </p>

              <p
                className=" font"
                style={{
                  textAlign: "center",
                  width: "90%",
                  margin: "0px auto",
                  fontWeight: "600",
                  fontStyle: "italic",
                }}
              >
                I would sincerely like to thank the RVS fraternity for
                discovering myself into a better version of myself.
                <br />
              </p>

              <p
                className=" font"
                style={{
                  textAlign: "center",
                  width: "90%",
                  margin: "0px auto",
                  fontWeight: "600",
                  fontStyle: "italic",
                }}
              >
                I am sure the upcoming RVS College of Physiotherapy batches will
                continue the same rich legacy.
                <span style={{ fontSize: "30px", color: "#a60d1e" }}>"</span>
                <br />
              </p>
              <h3
                className=" font font-center-sm-heading"
                style={{
                  color: "#4a4a4a",
                  fontSize: "16px",
                  paddingTop: 10,
                  margin: 0,
                  fontWeight: "400",
                  textAlign: "center",
                  width: "98%",
                }}
              >
                Branch Manager & Senior Physiotherapist <br /> Sparrc Institute,
                Bangalore
              </h3>

              <h4
                style={{
                  color: "#8d0010",
                  textAlign: "center",
                  marginTop: "0px",
                  fontSize: "20px",
                  marginBottom: "50px",
                }}
                className="  primary font"
              >
                Ms. P. Vidhya - 2002 Batch
              </h4>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className=" display" style={{ margin: "0px" }}>
          <div className="col-md-12  bggrey">
            <div>
              <p
                className=" font"
                style={{
                  textAlign: "center",
                  width: "90%",
                  paddingTop: "50px",
                  margin: "0px auto",
                  fontWeight: "600",
                  fontStyle: "italic",
                }}
              >
                <span style={{ fontSize: "50px", color: "#a60d1e" }}>"</span>I
                recollect the essential tools provided in curriculum training,
                wide exposure to different areas of the profession offering
                clinical training, participation in clinical conferences and
                seminars, and a conducive learning environment with hands-on
                training. Now, I see an enriched team with the highest degrees
                in the profession as faculty, with college offering undergrad to
                Ph.D. programs. All round development attained with skills
                learned as a student and working team member. Always be grateful
                for the opportunity to work, the overall knowledge gained, and
                the friendships made and maintained until now. Taking this
                chance to thank the Management, my Principal, and my dear
                faculty team for shaping my career.
                <br />
              </p>

              <p
                className=" font"
                style={{
                  textAlign: "center",
                  width: "90%",
                  margin: "0px auto",
                  fontWeight: "600",
                  fontStyle: "italic",
                }}
              >
                Wonderful alma mater of mine, little over two decades, I still
                cherish and boast of the time I spent on the campus, love
                visiting every time I’m in town. I enjoyed both my bachelor's
                and master’s program training, which has provided enough
                resources.{" "}
                <span style={{ fontSize: "30px", color: "#a60d1e" }}>"</span>
                <br />
              </p>
              <h3
                className=" font font-center-sm-heading"
                style={{
                  color: "#4a4a4a",
                  fontSize: "16px",
                  paddingTop: 10,
                  margin: 0,
                  fontWeight: "400",
                  textAlign: "center",
                  width: "98%",
                }}
              >
                Physical Therapist
                <br />
                Elara Caring, Michigan USA
              </h3>

              <h4
                style={{
                  color: "#8d0010",
                  textAlign: "center",
                  marginTop: "0px",
                  fontSize: "20px",
                  marginBottom: "50px",
                }}
                className="  primary font"
              >
                Soundararajan Duraisamy
              </h4>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div align="center">
        <p>
          <a href="/others/placement" class="btn-default btn6 font">
            View More
          </a>
        </p>
      </div>
    </div>
  );
}
